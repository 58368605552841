import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';

interface PropsType {
  last: boolean;
  title: string;
  descriptionEn: string;
  descriptionKr: string;
}

const textLoop = keyframes`
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(var(--total-move), 0, 0);
    }
`;

const ContainerStyle = styled.div<{ last: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: 1;
  gap: 7vh;
  animation: ${textLoop} 10000s linear infinite;
  margin-right: ${(props) => (props.last ? '320px' : '220px')};

  .shortform-item-head {
    font-size: 320px;
    font-weight: 700;
    position: relative;
    overflow: visible;

    &::after {
      content: '';
      position: absolute;
      left: ${(props) => (props.last ? '105%' : '100%')};
      top: calc(50% - 40px);
      width: 240px;
      height: 1px;
      border-top: 1px dashed #fff;
      margin-top: 2rem;
      transform: rotate(-60deg);
      z-index: 50;
      opacity: 0.5;
    }
  }

  .shortform-item-desc {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    line-height: 1.2;
    gap: 40px;

    .shortform-item-icon {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      & > svg {
        transform: rotate(45deg);
      }
    }

    .shortform-item-text {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      & > div {
        font-size: 1.2rem;
      }

      .shortform-item-title {
        //font-size: 1rem;
      }

      .shortform-item-subtitle {
        //font-size: 1rem;
      }
    }
  }
`;

function NewlineText({ text }) {
  const newText = text.split('\n').map((str, index, array) => (
    <React.Fragment key={index}>
      {str}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));

  return <div>{newText}</div>;
}

const ShortFormItem: React.FC<PropsType> = ({
  last,
  title,
  descriptionKr,
  descriptionEn,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <ContainerStyle last={last}>
        <div className="shortform-item-head">{title}</div>
        <div className="shortform-item-desc">
          <div className="shortform-item-icon">
            <ArrowUp />
          </div>
          <div className="shortform-item-text">
            <div className="shortform-item-title">
              {NewlineText({ text: descriptionEn })}
            </div>
            <div className="shortform-item-subtitle">
              {NewlineText({ text: descriptionKr })}
            </div>
          </div>
        </div>
      </ContainerStyle>
      {/*<div>*/}
      {/*  <Slash />*/}
      {/*</div>*/}
    </div>
  );
};

export default ShortFormItem;
