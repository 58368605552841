import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import AigcWorkDetail from './AigcWorkDetail';
import AigcWorkDetailMo from './AigcWorkDetailMo';

const AigcWorkDetailWrapper: React.FC = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile);

  useEffect(() => {
    const resizeHandler = () => {
      const windowWidth = window.innerWidth;
      if (isMobile || windowWidth < 1024) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [isMobile]);

  return <>{isMobileView ? <AigcWorkDetailMo /> : <AigcWorkDetail />}</>;
};

export default AigcWorkDetailWrapper;
