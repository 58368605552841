import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 40px 40px 0 0;

  .aigc-saas-header {
    line-height: 1;
    // padding-left: 40px;
    margin-bottom: 80px;

    .aigc-saas-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .aigc-saas-slash {
        font-size: 80px;
      }

      .aigc-saas-title {
        font-size: 32px;
        padding-top: 8px;
        font-weight: 700;
      }
    }

    .aigc-saas-header-desc {
      font-size: 14px;
      margin-top: 24px;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  .aigc-saas-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 48px;
  }

  .aigc-saas-content-title {
    font-size: 120px;
    font-weight: 700;
    line-height: 1;
  }

  .aigc-saas-numbering {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 40px;
  }

  .aigc-saas-content-wrapper {
    display: flex;
    gap: 40px;
    padding-left: 36px;
    margin-top: 60px;

    .aigc-saas-arrow-up {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .aigc-saas-desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 200px;

      .aigc-saas-desc-en {
        font-size: 10px;
        word-break: keep-all;
      }

      .aigc-saas-desc-kr {
        font-size: 14px;
        word-break: keep-all;
      }
    }
  }

  .aigc-saas-content-second {
    //padding: 96px 0 0 0;
  }

  .aigc-saas-content-second > .aigc-saas-content-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 40px;
  }

  .company-name {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 72px;
    font-weight: 700;
    line-height: 1;

    & > div {
      // padding: 40px;
    }

    & > div:last-child {
      color: #000;
      text-shadow:
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
  }
`;

const BrandingDrivenSectionMo: React.FC = () => {
  return (
    <ContainerStyle
      className="block-style"
      style={{ paddingTop: '0', paddingBottom: '200px' }}
    >
      <div className="aigc-saas-header">
        <div className="aigc-saas-wrapper">
          <div className="aigc-saas-slash">/</div>
          <div className="aigc-saas-title">
            <div>BRAINDING</div>
            <div>DRIVEN</div>
            <div className="aigc-saas-header-desc">브랜딩을 위한 솔루션</div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div style={{ paddingLeft: '36px' }}>
          <div className="company-name">
            <div>Branding</div>
            <div>Solution</div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              CREATIVELY UTILIZE THE MOST EFFECTIVE SHORT-FORM CONTENT IN
              DIGITAL FOR BRANDING
            </div>
            <div className="aigc-saas-desc-kr">
              디지털에서 가장 효과적인 숏폼 콘텐츠를, 브랜딩에 크리에이티브하게
              활용
            </div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              MAXIMIZE BRAND NEWNESS AND SUSTAINABILITY THROUGH COST-INNOVATED
              MULTI-CONTENT BRANDING WITH AI
            </div>
            <div className="aigc-saas-desc-kr">
              AI로 비용 혁신된 멀티콘텐츠 브랜딩으로, 브랜드 뉴니스와 지속가능성
              극대화
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default BrandingDrivenSectionMo;
