import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  //padding: 40px;

  .aigc-short-form-header {
    line-height: 1;
    margin-bottom: 80px;

    .aigc-short-header-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .aigc-short-form-slash {
        font-size: 80px;
      }

      .aigc-short-form-title {
        font-size: 32px;
        padding-top: 8px;
        font-weight: 700;
      }
    }

    .aigc-short-form-header-desc {
      font-size: 14px;
      margin-top: 22px;
      line-height: 1.4;
      font-weight: 500;
    }
  }

  .aigc-short-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .aigc-short-content-title {
      padding-left: 4px;
      font-size: 140px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: -4px;
    }

    .aigc-short-content-wrapper {
      display: flex;
      padding-left: 40px;
      gap: 40px;

      .aigc-short-arrow-up {
        & > svg {
          transform: rotate(45deg);
        }
      }

      .aigc-short-desc-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .aigc-short-desc-en {
          font-size: 10px;
          width: 160px;
        }

        .aigc-short-desc-kr {
          font-size: 14px;
          width: 160px;
          transfrom: scale(0.8);
        }
      }
    }
  }

  .aigc-short-line {
    padding: 0 60px;
    //width: 200px;
    //height: 1px;
    //border-bottom: 1px solid #fff;
    //transform: rotate(120deg);
    //position: absolute;
    //top: 120px;
    //left: 260px;
  }
  .shortFormItemBox {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 60px);
    margin: 0 auto;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 60px auto;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      align-items: flex-start;
    }
  }
`;

const ShortFormItem: React.FC<{
  title: string;
  descEn: string;
  descKr: string;
}> = ({ title, descEn, descKr }) => {
  return (
    <div className="shortFormItemBox">
      <div className="aigc-short-content">
        <div className="aigc-short-content-title">{title}</div>
        <div className="aigc-short-content-wrapper">
          <div className="aigc-short-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-short-desc-wrapper">
            <div className="aigc-short-desc-en">{descEn}</div>
            <div className="aigc-short-desc-kr">{descKr}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const items = [
  <ShortFormItem
    title="7X"
    descEn="EMPHASIS ON PRODUCT RATHER THAN MODEL NUMBER OF CONVERSIONS OF MATERIALS"
    descKr="모델보다 제품 강조한 소재의 전환건수"
    key={0}
  />,
  <ShortFormItem
    title="3X"
    descEn="TIME SPENT ON PRODUCT COMPARED TO IMAGE ADVERTISING"
    descKr="이미지 광고 대비 상품에 머무는 시간"
    key={1}
  />,
  <ShortFormItem
    title="2X"
    descEn="INCREASED AWARENESS TWICE AS HIGH COMPARED TO STATIC ADVERTISING"
    descKr="정적 광고 대비 2배 높은 인지도 상승"
    key={2}
  />,
  <ShortFormItem
    title="4X"
    descEn="4 TIMES HIGHER SEARCH DRIVE COMPARED TO STATIC ADS"
    descKr="정적 광고 대비 4배 높은 검색 유도"
    key={3}
  />,
  <ShortFormItem
    title="48%"
    descEn="VIDEO AD SALES INCREASE COMPARED TO STATIC ADS"
    descKr="정적 광고 대비 동영상 광고 판매UP"
    key={4}
  />,
];

const AigcShortFormMo: React.FC = () => {
  return (
    <ContainerStyle className="block-style" style={{ paddingTop: '40px' }}>
      <div className="aigc-short-form-header">
        <div className="aigc-short-header-wrapper">
          <div className="aigc-short-form-slash">/</div>
          <div className="aigc-short-form-title">
            <div>AIGC</div>
            <div>SHORT-FORM</div>
            <div className="aigc-short-form-header-desc">
              AI로 3D 광고콘텐츠를 더 빠르고
              <br />
              효율적으로 만들어 드립니다.
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div>{items}</div>
        {/* <Slider speed={50}>{items}</Slider> */}
      </div>
    </ContainerStyle>
  );
};

export default AigcShortFormMo;
