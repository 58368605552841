import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

interface PropsType {
  item: Project;
  index: number;
  setCurrentItem: (id: number) => void;
}

interface Project {
  id: number;
  thumbnail: string;
  collaboration_brand: string;
  category:
    | '360-premium'
    | 'one-point-creative'
    | 'customized-creative'
    | 'luxury'
    | 'fooh'
    | 'aigc-saas'
    | 'ai-dressing';
  sub_title: string;
  date: string;
  kv_link?: string;
  vidio_direction: 'horizontal' | 'vertical' | 'square';
  is_public: boolean;
  logo: string;
  logo_bg_color: string;
  description: string;
  tech: string;
  keywords: string;
}

const hoverOutAnim = keyframes`
    0% {
        transform: scale(1.6);
    }
    100% {
        transform: scale(1);
    }
`;

const blur = (isHovered: boolean) => keyframes`
    0% {
        //opacity: 0;
        filter: ${!isHovered ? 'blur(50px)' : 'blur(0)'};
        opacity: 1;
        //transfrom: scale(1.3);
    }
    30% {
        filter: ${!isHovered ? 'blur(50px)' : 'blur(0)'};
        opacity: 1;
    }
    70% {
        //opacity: 1;
        filter: blur(0);
        opacity: 1;
        transform: scale(1.6);
    }
    71% {
        filter: blur(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const scaleAnim = keyframes`
    0% {
        background-color: rgba(229, 231, 235, 1);
    }
    100% {
        background-color: transparent;
    }
`;

const noiseAnimation = keyframes`
    0%, 100% {
        background-position: 0 0;
    }

    0% {
        opacity: 0;
    }
    30% {
        opacity: 0.3;
    }
    90% {
        opacity: 0.3;
    }
    100% {
        opacity: 0;
    }
`;

const hoverAnim = keyframes`
    0% {
        transform: scale(1);

    }
    100% {
        transform: scale(1.6);

    }
`;

const ContainerStyle = styled(Link)<{
  show: boolean;
  imgUrl: string;
  animationDelay: number;
  isHovered: boolean;
}>`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;

    &.loaded .grid-item::after {
        ${(props) =>
          props.show &&
          css`
            animation: ${blur(props.isHovered)} 1.2s ease-in-out forwards;
            background-image: url(${props.imgUrl});
          `}
    }

    &.loaded, grid-item:hover::after {
        animation: ${hoverAnim} 1s ease-in-out;
    }

    .noise-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 100% 100%;
        background-image: url('/images/noise.png');
        background-repeat: no-repeat;
        mix-blend-mode: multiply;

        opacity: 0;
    }

    .grid-item {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        z-index: 2;

        &::after {
            will-change: transform, opacity, filter;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position: center;
            transition: transform 1s ease-in-out;
            transform: scale(1.6);
        }

        &:hover::after {
            animation: ${hoverAnim} 1s ease-in-out;
        }


        //&:hover::after {
        //    transform: scale(2); // 호버 상태에서의 scale 값
        //    transition: transform 1s ease-in-out;
        //}

        .grid-item-content {
            position: absolute;
            padding: 20px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0; /* 초기 상태에서 투명도 0 */
            transition: opacity 0.2s ease-in-out; /* 투명도 변경에 대한 부드러운 전환 */
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column; /* 자식 엘리먼트들을 세로로 정렬 */
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            z-index: 2;
        }

        &:hover .grid-item-content {
            opacity: 1;
        }

        .item-brand {
            font-size: 1.5rem;
            font-weight: 700;
            width: 100%;

            @media (max-width: 768px) {
                font-size: 1.2rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .item-title {
            margin-top: auto;
            font-size: 16px;
            font-weight: 700;
        }

        .item-subtitle {
            @media (max-width: 768px) {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .item-discover {
            display: flex;
            align-items: center;
            margin-top: 10px;
            border: 1px solid #fff;
            padding: 8px 12px 10px 12px;
            transition: background-color 0.5s ease-in-out;
            line-height: 1;
            text-decoration: none;
            color: #fff;

            & > * {
                margin: auto;
            }

            //&:hover {
            //    background-color: #000;
            //    cursor: pointer;
            //}

            @media (max-width: 1024px) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
`;

const PortfolioItem: React.FC<PropsType> = ({
  item,
  index,
  setCurrentItem,
}) => {
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const itemRef = useRef<HTMLAnchorElement>(null);

  const containerClassName = loaded ? 'loaded' : '';

  useEffect(() => {
    // 이미지 로딩을 확인하는 로직
    if (item.thumbnail) {
      const img = new Image();
      img.src = item.thumbnail;
      img.onload = () => {
        // 이미지 로드 완료 시 loaded 상태를 true로 설정
        requestAnimationFrame(() => setLoaded(true));
      };
    }
  }, [item.thumbnail]);

  useEffect(() => {
    // Intersection Observer를 사용하여 컴포넌트가 뷰포트에 들어왔는지 확인
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 컴포넌트가 뷰포트에 들어오면 show 상태를 true로 설정
            setShow(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <ContainerStyle
      isHovered={isHovered}
      className={`${containerClassName} ${isHovered ? 'hovered' : ''}`}
      ref={itemRef}
      show={show && loaded}
      imgUrl={item.thumbnail}
      animationDelay={index}
      onClick={() => isMobile && setCurrentItem(item.id)}
      onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
      to={`/aigc-work/${item.id}`}
    >
      <div className="noise-overlay"></div>
      <div className="grid-item">
        {!isMobile && (
          <>
            <div
              className="grid-item-content"
              onClick={() => !isMobile && setCurrentItem(item.id)}
            >
              <div className="item-brand">{item.collaboration_brand}</div>
              <div className="item-title">
                {item?.category.toUpperCase() === 'ONE-POINT-CREATIVE'
                  ? 'ONE-POINT CREATIVE'
                  : item?.category.toUpperCase() === 'CUSTOMIZED-CREATIVE'
                    ? 'CUSTOMIZED CREATIVE'
                    : item?.category.toUpperCase() === '360-PREMIUM'
                      ? '360° PREMIUM'
                      : item?.category.toUpperCase()}
              </div>
              <div className="item-subtitle">{item.sub_title}</div>
              <div className="item-discover">{'DISCOVER >'}</div>
            </div>
          </>
        )}
      </div>
    </ContainerStyle>
  );
};

export default PortfolioItem;
