import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import useObserverHook from '../../hooks/useObserverHook';
import WeCreateSection from './WeCreateSection';

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const ContainerStyle = styled.div<{ hovered: string; fontSize: string }>`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    .saas-head {
        color: #fff;
        display: flex;
        justify-content: flex-start;
        position: absolute;
        left: 14.3vw;
        top: 4vh;

        .slash-text {
            font-size: 180px;
            font-weight: 500;
            color: #fff;
            margin-right: 20px;
            line-height: 1;
        }

        .saas-title-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding-top: 6px;

            .saas-title-text {
                font-size: 90px;
                font-weight: 700;
                color: #fff;
                margin: 0;
                padding: 0;
                line-height: 1;
            }
        }

        .saas-head-desc {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            //margin-left: 10vw;
            padding-bottom: 10px;
            font-size: 1.2rem;
            position: absolute;
            bottom: 0;
            left: calc(100vw / 2);
        }
    }

    .saas-body {
        position: relative;
        width: 100%;
        height: 100%;
        color: #fff;
        line-height: 1;
        cursor: pointer;

        .body-left {
            display: flex;
            flex-direction: column;
            gap: 80px;
            position: absolute;
            left: 14.3vw;
            top: 28vh;
            transition: transform 0.3s cubic-bezier(.26, .28, .32, .87);
            transform-origin: 10% 10%;
            transform: ${(props) =>
              props.hovered === 'right' ? 'scale(0.8)' : 'scale(1)'};

            &:hover {
                transform: ${(props) =>
                  props.hovered === 'left'
                    ? 'scale(1.2)'
                    : props.hovered === 'right'
                      ? 'scale(0.5)'
                      : 'scale(1)'};
            }
        }

        .body-slash {
            position: absolute;
            top: calc(50% + 4vh);
            left: calc(50% - 8vw);
            width: 400px;
            border-bottom: 1px solid #fff;
            opacity: 0.5;
            transition: transform 0.3s cubic-bezier(.26, .28, .32, .87);
            transform: ${(props) =>
              props.hovered === 'left'
                ? 'translate(18%, 15%) rotate(-60deg);'
                : props.hovered === 'right'
                  ? 'translate(-24%, -15%) rotate(-60deg);'
                  : 'rotate(-60deg);'});
        }

        .body-right {
            display: flex;
            flex-direction: column;
            gap: 80px;
            position: absolute;
            right: 12.3vw;
            bottom: 15vh;
            transition: transform 0.3s cubic-bezier(.26, .28, .32, .87);
            transform-origin: 90% 90%;
            transform: ${(props) =>
              props.hovered === 'left' ? 'scale(0.8)' : 'scale(1)'};

            &:hover {
                transform: ${(props) =>
                  props.hovered === 'right'
                    ? 'scale(1.2)'
                    : props.hovered === 'left'
                      ? 'scale(0.5)'
                      : 'scale(1)'};
            }
        }

        .right-title-text,
        .left-title-text {
            //font-size: 23vh;
            font-size: ${(props) => props.fontSize};
            font-weight: 700;
            text-shadow: -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
        }

        .right-title-text {
            color: ${(props) =>
              props.hovered !== 'left' ? '#fff' : '#000' + ''};
            transition: color 0.3s ease-in-out;
        }

        .left-title-text {
            color: ${(props) => (props.hovered !== 'right' ? '#fff' : '#000')};
            transition: color 0.3s ease-in-out;
        }

        .body-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            gap: 40px;

            .body-icon {
                & > svg {
                    transform: rotate(45deg);
                }
            }

            .body-desc {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 1rem;
                gap: 40px;
                line-height: 1.2;

                .desc-en {
                }

                .desc-kr {
                }
            }
        }
    }
`;

const AigcSaasSection = () => {
  const [hovered, setHovered] = useState('');
  const { ref, isVisible } = useObserverHook();
  // const [fontSize, setFontSize] = useState(90);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getFontSize = (width) => {
    const maxWidth = 1920,
      minWidth = 1280;
    const minFontSizePx = 150;
    const maxFontSizeVh = 23;

    let fontSize;
    if (width <= minWidth) {
      fontSize = `${minFontSizePx}px`;
    } else if (width >= maxWidth) {
      fontSize = `${maxFontSizeVh}vh`;
    } else {
      const scale = (width - minWidth) / (maxWidth - minWidth);
      const fontSizePx =
        minFontSizePx +
        ((maxFontSizeVh / 100) * window.innerHeight - minFontSizePx) * scale;
      fontSize = `${fontSizePx}px`;
    }

    return fontSize;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ContainerStyle
      hovered={hovered}
      fontSize={getFontSize(windowWidth)}
      ref={ref}
    >
      <div className="saas-head">
        <div className="slash-text">/</div>
        <div className="saas-title-wrapper">
          {isVisible && (
            <>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="AIGC"
                    fontSize="90px"
                    addDelay={500}
                    notBold
                  />
                </MaskedContainer>
              </div>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="SAAS"
                    fontSize="90px"
                    addDelay={800}
                    notBold
                  />
                </MaskedContainer>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="saas-head-desc">
            AI로 콘텐츠 제작을 최적화 할 수<br />
            있는 툴을 제공합니다.
          </div>
        </div>
      </div>
      <div className="saas-body">
        <div
          className="body-left"
          onMouseEnter={() => setHovered('left')}
          onMouseLeave={() => setHovered('')}
        >
          <div className="left-title-text">Photo</div>
          <div className="body-wrapper">
            <div className="body-icon">
              <ArrowUp />
            </div>
            <div className="body-desc">
              <div className="desc-en">
                PHOTOS TAKEN WITH A PHONE
                <br />
                ARE ALSO OPTIMIZED WITH
                <br />
                GENERATIVE AI AND USED
                <br />
                IMMEDIATELY FOR E-COMMERCE,
                <br />
                ETC.
              </div>
              <div className="desc-kr">
                폰으로 찍은 사진도 생성형AI로
                <br />
                최적화해, 이커머스 등에 바로 사용
              </div>
            </div>
          </div>
        </div>
        <div className="body-slash"></div>
        <div
          className="body-right"
          onMouseEnter={() => setHovered('right')}
          onMouseLeave={() => setHovered('')}
        >
          <div className="right-title-text">Easy</div>
          <div className="body-wrapper">
            <div className="body-icon">
              <ArrowUp />
            </div>
            <div className="body-desc">
              <div className="desc-en">
                REMOVING BACKGROUNDS,
                <br />
                ADDING SHADOWS, AND APPLYING
                <br />
                TEMPLATES CAN BE DONE WITH A<br />
                SMALL MONTHLY SUBSCRIPTION.
              </div>
              <div className="desc-kr">
                배경제거 - 그림자넣기 - 템플릿
                <br />
                적용 등의 작업을 소액의 월구독으로
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default AigcSaasSection;
