import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';
import useObserverHook from '../../hooks/useObserverHook';
import WeCreateSection from './WeCreateSection';

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const ContainerStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 40px 40px 0 0;
  z-index: 50;
  position: relative;
  pointer-events: none;

  .saas-head {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    position: relative;
    left: 14.3vw;
    top: 4vh;

    .slash-text {
      font-size: 180px;
      font-weight: 500;
      color: #fff;
      margin-right: 20px;
      line-height: 1;
    }

    .saas-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 12px;
      position: relative;

      .saas-title-text {
        font-size: 90px;
        font-weight: 700;
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 0.9;
        position: relative;
      }
    }

    .saas-head-desc {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      //margin-left: 10vw;
      padding-bottom: 10px;
      font-size: 1.2rem;
      position: absolute;
      bottom: 0;
      left: calc(100vw / 2);
    }
  }

  .aigc-saas-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 48px;
  }

  .aigc-saas-content-title {
    font-size: 120px;
    font-weight: 700;
    line-height: 1;
  }

  .aigc-saas-numbering {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 40px;
  }

  .aigc-saas-content-wrapper {
    display: flex;
    gap: 40px;
    padding-left: 80px;

    .aigc-saas-arrow-up {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .aigc-saas-desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 200px;
      color: #fff;

      .aigc-saas-desc-en {
        font-size: 10px;
        color: #fff;
      }

      .aigc-saas-desc-kr {
        font-size: 14px;
      }
    }
  }

  .aigc-saas-content-second {
    //padding: 96px 0 0 0;
  }

  .aigc-saas-content-second > .aigc-saas-content-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 40px;
  }

  .company-name {
    color: white;
    display: flex;
    font-size: 280px;
    font-weight: 700;

    & > div {
      padding: 40px;
    }

    & > div:last-child {
      color: #000;
      text-shadow:
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
  }

  .sales-driven-section {
    &:not(:last-child) {
      margin-bottom: 200px;
    }

    .sale-driven-title {
      font-size: 200px;
      font-weight: 700;
    }

    .sales-driven-content {
      display: flex;
      flex-direction: column;
      gap: 48px;
      padding-left: 490px;
    }

    .sales-driven-desc-title {
      font-size: 24px;
      font-weight: 700;
    }

    .sales-driven-desc-body {
      display: flex;
      gap: 60px;

      .sale-driven-desc-en {
        width: 360px;
      }

      .sale-driven-desc-kr {
        width: 360px;
      }

      & > div {
        display: flex;
      }

      & > :nth-child(1) {
        & svg {
          transform: rotate(45deg);
        }
      }

      &:nth-child(2) {
        & > :nth-child(2) {
        }
      }

      &:nth-child(3) {
      }
    }
  }
`;

const BrandingDrivenSectionMo: React.FC = () => {
  const { ref, isVisible } = useObserverHook();

  return (
    <ContainerStyle
      className="block-style"
      style={{ paddingTop: '360px', paddingBottom: '200px' }}
      ref={ref}
    >
      <div className="saas-head">
        <div className="slash-text">/</div>
        <div className="saas-title-wrapper">
          {isVisible && (
            <>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="BRAINDING"
                    fontSize="90px"
                    addDelay={500}
                    notBold
                  />
                </MaskedContainer>
              </div>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="DRIVEN"
                    fontSize="90px"
                    addDelay={800}
                    notBold
                  />
                </MaskedContainer>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="saas-head-desc">브랜딩을 위한 솔루션</div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <Slider speed={300}>
          <div className="company-name">
            <div>Branding</div>
            <div>Solution</div>
          </div>
          <div className="company-name">
            <div>Branding</div>
            <div>Solution</div>
          </div>
        </Slider>
        <div className="sales-driven-section">
          <div className="sales-driven-content">
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                CREATIVELY UTILIZE THE MOST EFFECTIVE SHORT-FORM CONTENT IN
                DIGITAL FOR BRANDING
              </div>
              <div className="sale-driven-desc-kr">
                디지털에서 가장 효과적인 숏폼 콘텐츠를, 브랜딩에
                크리에이티브하게 활용
              </div>
            </div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                MAXIMIZE BRAND NEWNESS AND SUSTAINABILITY THROUGH COST-INNOVATED
                MULTI-CONTENT BRANDING WITH AI
              </div>
              <div className="sale-driven-desc-kr">
                AI로 비용 혁신된 멀티콘텐츠 브랜딩으로, 브랜드 뉴니스와
                지속가능성 극대화
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default BrandingDrivenSectionMo;
