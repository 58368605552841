import React from 'react';
import styled from 'styled-components';

const Contact: React.FC = () => {
  return (
    <div>
      <h1>Contact</h1>
    </div>
  );
};

export default Contact;
