import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ShortFormItem from './ShortFormItem';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ListChildComponentProps } from 'react-window';
import Slider from './Slider';
import WeCreateSection from './WeCreateSection';
import useObserverHook from '../../hooks/useObserverHook';

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const ContainerStyle = styled.div`
  position: relative;

  .scroller-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .shortform-head {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    left: 14.3vw;
    top: 4vh;

    .slash-text {
      font-size: 180px;
      font-weight: 500;
      color: #fff;
      margin-right: 20px;
      line-height: 1;
    }

    .shortform-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 12px;

      .shortform-title-text {
        font-size: 90px;
        font-weight: 700;
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 0.9;
      }
    }

    .shortform-head-desc {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      //margin-left: 10vw;
      padding-bottom: 10px;
      font-size: 1.2rem;
      position: absolute;
      bottom: 0;
      left: calc(100vw / 2);
    }
  }

  .inner-vertical-scroll-wrapper {
    width: 100vw;
    height: 55vh;
    position: absolute;
    top: calc(50% - 22.5vh);
    left: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 400px;
  }

  .flow-texts {
    width: 100vw;
    height: 55vh;
    display: flex;
    flex-wrap: nowrap;
    flex: 0 0 auto;
    white-space: nowrap;
    overflow: hidden;
    transition: 0.5s;
    gap: 12vw;
  }
`;

const InnerScrollable = styled.div`
  width: 210vw;
  height: 55vh;
  //background: linear-gradient(to right, #ff758c 0%, #3e8f3e 50%, #3f8ac7 100%);
  position: absolute;
  top: calc(50% - 20vh);
  left: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`;

const texts = [
  {
    title: '7X',
    descriptionEn:
      'Emphasis on product\nrather than model\nNumber of conversions\nof materials',
    descriptionKr: '모델보다 제품 강조한\n소재의 전환건수',
  },
  {
    title: '3X',
    descriptionEn:
      'Emphasis on product\nrather than model\nNumber of conversions\nof materials',
    descriptionKr: '이미지 광고 대비\n상품에 머무는 시간',
  },
  {
    title: '2X',
    descriptionEn:
      'Increased\nawareness twice as\nhigh compared to\nstatic advertising',
    descriptionKr: '정적 광고 대비\n2배 높은 인지도 상승',
  },
  {
    title: '4X',
    descriptionEn: '4 times higher\nsearch drive\ncompared to static\nads',
    descriptionKr: '정적 광고 대비\n4배 높은 검색 유도',
  },
  {
    title: '48%',
    descriptionEn: 'Video ad sales\nincrease compared\nto static ads',
    descriptionKr: '정적 광고 대비\n동영상 광고 판매UP',
  },
];

const AigcShortFormSection: React.FC = () => {
  const [isInCenter, setIsInCenter] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('down');
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const lastScrollY = useRef(0);
  const itemCount = 1000;
  const [items, setItems] = useState<number[]>(
    Array.from(Array(itemCount).keys())
  );

  const { ref, isVisible } = useObserverHook();

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const newItems = items.concat(
          Array.from(
            { length: stopIndex - startIndex + 1 },
            (_, index) => startIndex + index
          )
        );
        setItems(newItems);
        resolve();
      }, 1000); // Simulate network request delay
    });
  };

  const isItemLoaded = (index: number): boolean => index < items.length;

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
    lastScrollY.current = currentScrollY;
  };

  const checkIfInCenter = () => {
    if (!containerRef.current) return;
    const { top, bottom } = containerRef.current.getBoundingClientRect();
    const vh = window.innerHeight || document.documentElement.clientHeight;
    const containerCenter = (top + bottom) / 2; // 컨테이너 중앙 위치 계산

    if (containerCenter > vh * 0.4 && containerCenter < vh * 0.6) {
      setIsInCenter(true);
    } else {
      setIsInCenter(false);
    }
  };

  useEffect(() => {
    const companyNameWrapper = document.querySelector(
      '.shortform-item-wrapper'
    ) as HTMLElement | null;

    if (companyNameWrapper) {
      const rect = companyNameWrapper.getBoundingClientRect();
      console.log(rect.width);
      const width = (rect.width / window.innerWidth) * 100000;
      const totalWidth = width + 1.4881 * 2;

      document.documentElement.style.setProperty(
        '--total-move',
        `-${totalWidth}vw`
      );
    }
  }, []);

  const Item = ({ index, style }) => {
    const itemData = texts[index % texts.length];
    return (
      <div style={style} className="shortform-item-wrapper">
        <ShortFormItem
          title={itemData.title}
          descriptionEn={itemData.descriptionEn}
          descriptionKr={itemData.descriptionKr}
          last={false}
        />
      </div>
    );
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', checkIfInCenter, { passive: true });
  //   return () => window.removeEventListener('scroll', checkIfInCenter);
  // }, []);
  //
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleWheel = (e: WheelEvent) => {
  //     if (!containerRef.current) return;
  //
  //     const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
  //     const maxScrollLeft = scrollWidth - clientWidth;
  //     const threshold = 50;
  //
  //     if (
  //       (scrollLeft <= threshold && e.deltaY < 0) ||
  //       (scrollLeft >= maxScrollLeft - threshold && e.deltaY > 0)
  //     ) {
  //       // nothing
  //     } else {
  //       e.preventDefault();
  //       containerRef.current.scrollTo({
  //         left: containerRef.current.scrollLeft + e.deltaY * 2,
  //         behavior: 'smooth',
  //       });
  //     }
  //   };
  //
  //   if (isInCenter) {
  //     window.addEventListener('wheel', handleWheel, { passive: false });
  //   }
  //
  //   return () => window.removeEventListener('wheel', handleWheel);
  // }, [isInCenter]);

  return (
    <ContainerStyle className="section-block" ref={ref}>
      <div className="shortform-head">
        <div className="slash-text">/</div>
        <div className="shortform-title-wrapper">
          {isVisible && (
            <>
              <div className="shortform-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="AIGC"
                    fontSize="90px"
                    addDelay={500}
                    notBold
                  />
                </MaskedContainer>
              </div>
              <div className="shortform-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="SHORT FORM"
                    fontSize="90px"
                    addDelay={800}
                    notBold
                  />
                </MaskedContainer>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="shortform-head-desc">
            AI로 3D 광고콘텐츠를 더 빠르고
            <br />
            효율적으로 만들어 드립니다.
          </div>
        </div>
      </div>
      <div style={{ margin: '80px 0' }}></div>
      <Slider speed={100}>
        <ShortFormItem
          title={texts[0].title}
          descriptionEn={texts[0].descriptionEn}
          descriptionKr={texts[0].descriptionKr}
          last={false}
        />

        <ShortFormItem
          title={texts[1].title}
          descriptionEn={texts[1].descriptionEn}
          descriptionKr={texts[1].descriptionKr}
          last={false}
        />

        <ShortFormItem
          title={texts[2].title}
          descriptionEn={texts[2].descriptionEn}
          descriptionKr={texts[2].descriptionKr}
          last={false}
        />
        <ShortFormItem
          title={texts[3].title}
          descriptionEn={texts[3].descriptionEn}
          descriptionKr={texts[3].descriptionKr}
          last={false}
        />
        <ShortFormItem
          title={texts[4].title}
          descriptionEn={texts[4].descriptionEn}
          descriptionKr={texts[4].descriptionKr}
          last={true}
        />
      </Slider>
      {/*<div className="scroll-wrapper">*/}
      {/*  <InfiniteLoader*/}
      {/*    isItemLoaded={(index) => index < itemCount}*/}
      {/*    itemCount={itemCount}*/}
      {/*    loadMoreItems={(startIndex, stopIndex) => Promise.resolve()}*/}
      {/*  >*/}
      {/*    {({ onItemsRendered, ref }) => (*/}
      {/*      <List*/}
      {/*        height={window.innerHeight * 0.5}*/}
      {/*        itemCount={itemCount}*/}
      {/*        itemSize={770}*/}
      {/*        width={window.innerWidth}*/}
      {/*        onItemsRendered={onItemsRendered}*/}
      {/*        layout="horizontal"*/}
      {/*        style={{ overflowX: 'hidden' }}*/}
      {/*        ref={ref}*/}
      {/*      >*/}
      {/*        {Item}*/}
      {/*      </List>*/}
      {/*    )}*/}
      {/*  </InfiniteLoader>*/}
      {/*</div>*/}
    </ContainerStyle>
  );
};

export default AigcShortFormSection;
