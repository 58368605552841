import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import axios from 'axios';
import process from 'process';

interface ClientImage {
  id: number;
  image: string;
  client_name: string;
}

const ContainerStyle = styled.div`
  display: flex;
  padding: 0 0 200px 0;

  .client-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .client-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      padding-left: 32px;

      .client-title-text {
        font-size: 32px;
        font-weight: 900;
        color: #fff;
      }

      .client-head-line {
        display: flex;
        width: 50%;
        align-items: center;
        padding-right: 40px;

        & > div {
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed white;
          opacity: 0.3;
        }
      }
    }

    .client-body {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      grid-template-rows: repeat(4, minmax(60px, 1fr));
      grid-auto-rows: 100px;
      grid-auto-flow: column;
      row-gap: 24px;
      column-gap: 60px;
      margin-top: 40px;
      padding-right: 60px;
      overflow-x: hidden;
      white-space: nowrap;

      img {
        max-width: 100px;
      }
    }
  }
`;

const ClientBody: React.FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const [velocity, setVelocity] = useState(0);
  const [isDecelerating, setIsDecelerating] = useState(false);

  const [clientImages, setClientImages] = useState<ClientImage[]>([]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/information/client-image/`
      );
      if (response.data) {
        setClientImages([...response.data, ...response.data]);
      }
    } catch (error) {
      console.error('Fetching error:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    startDrag(e.pageX);
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    startDrag(e.touches[0].clientX);
  };

  const startDrag = (clientX: number) => {
    setIsDragging(true);
    setIsDecelerating(false);
    setVelocity(0);
    setStartX(clientX - (ref.current?.offsetLeft ?? 0));
    setScrollLeft(ref.current?.scrollLeft ?? 0);
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      continueDrag(e.pageX);
    }
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (isDragging) {
      continueDrag(e.touches[0].clientX);
    }
  };

  const continueDrag = (clientX: number) => {
    const x = clientX - (ref.current?.offsetLeft ?? 0);
    const newScrollLeft = scrollLeft - (x - startX) * 2;

    if (ref.current) {
      ref.current.scrollLeft = newScrollLeft;
      setVelocity(newScrollLeft - lastScrollLeft);
      setLastScrollLeft(newScrollLeft);
    }
  };

  const onMouseUpOrLeave = () => {
    endDrag();
  };

  const onTouchEnd = () => {
    endDrag();
  };

  const endDrag = () => {
    setIsDragging(false);
    if (!isDecelerating && Math.abs(velocity) > 0.95) {
      setIsDecelerating(true);
      requestAnimationFrame(decelerateScroll);
    }
  };

  const decelerateScroll = () => {
    if (!ref.current || !isDecelerating) return;

    const newVelocity = velocity * 0.95;
    setVelocity(newVelocity);

    ref.current.scrollLeft += newVelocity;

    if (Math.abs(newVelocity) < 1) {
      setIsDecelerating(false);
      setVelocity(0);
    } else {
      requestAnimationFrame(decelerateScroll);
    }
  };

  return (
    <div
      className="client-body"
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUpOrLeave}
      onMouseLeave={onMouseUpOrLeave}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
    >
      {clientImages.map((clientImage, index) => (
        <ClientItem
          key={index}
          id={clientImage.id}
          image={clientImage.image}
          client_name={clientImage.client_name}
        />
      ))}
      {clientImages.length % 4 !== 0 &&
        clientImages
          .slice(0, clientImages.length % 4)
          .map((clientImage, index) => (
            <ClientItem
              key={index}
              id={clientImage.id}
              image={clientImage.image}
              client_name={clientImage.client_name}
            />
          ))}
    </div>
  );
};

const ClientItem: React.FC<ClientImage> = ({ id, image, client_name }) => {
  return (
    <div
      style={{
        display: 'inline-block',
        pointerEvents: 'none',
      }}
    >
      <img
        src={image}
        alt={client_name}
        style={{ pointerEvents: 'none', userSelect: 'none' }}
      />
    </div>
  );
};

const ClientSectionMo: React.FC = () => {
  return (
    <ContainerStyle
      className="block-style"
      style={{ paddingRight: '0', paddingLeft: '0' }}
    >
      <div className="client-wrapper">
        <div className="client-head">
          <div className="client-title-text">CLIENT</div>
          <div className="client-head-line">
            <div></div>
          </div>
        </div>
        <Slider speed={300}>
          <ClientBody />
          <ClientBody />
        </Slider>
      </div>
    </ContainerStyle>
  );
};

export default ClientSectionMo;
