import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import usePostHook from '../../hooks/usePostHook';
import process from 'process';
import axios from 'axios';

interface ContactData {
  company_name: string;
  name: string;
  phone_number: string;
  email: string;
  content: string;
}

const ContainerStyle = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  margin: 220px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 50;

  .container {
    width: 66.6vw;
    z-index: 50;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .contact-form-row {
      width: 100%;
      //height: 97px;
      display: flex;
      justify-content: space-between;
      gap: 100px;

      .contact-form-column {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 60px;

        & > svg {
          transform: rotate(45deg);
        }

        .input-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          & > input {
            width: 100%;
            height: 60px;
            background-color: transparent;
            border: 1px solid rgba(255, 255, 255, 0.2);
            padding: 19px 24px;
            color: #fff;
          }

          & > textarea {
            width: 100%;
            height: 437px;
            padding: 19px 24px;
            background-color: transparent;
            border: 1px solid rgba(255, 255, 255, 0.2);
            color: #fff;
            resize: none;
          }

          & > input:focus,
          textarea:focus {
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.6);
          }

          & > div {
            margin-top: 16px;
            color: #ff0000;
          }
        }
      }
    }

    .contact-btn {
      width: 160px;
      height: 42px;
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      outline: none;
      color: #fff;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
`;

function isEmailFormat(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const ContactFormSection: React.FC = () => {
  const [contactForm, setContactForm] = useState({
    companyName: '',
    name: '',
    phoneNumber: '',
    email: '',
    content: '',
  });
  const [validationCheck, setValidationCheck] = useState(true);
  const [emailVailid, setEmailValid] = useState(true);

  const { data, loading, error, postData } = usePostHook<ContactData>(
    `${process.env.REACT_APP_API_URL}/api/contact/contact-us/`
  );

  const onSubmit = async () => {
    if (
      contactForm.companyName.trim() === '' ||
      contactForm.name.trim() === '' ||
      contactForm.phoneNumber.trim() === '' ||
      contactForm.email.trim() === '' ||
      contactForm.content.trim() === ''
    ) {
      setValidationCheck(false);
      // alert('모든 항목을 입력해주세요');
      return;
    } else if (!isEmailFormat(contactForm.email)) {
      setEmailValid(false);
      // alert('이메일 형식을 확인해주세요.');
      return;
    }
    await postData({
      company_name: contactForm.companyName,
      name: contactForm.name,
      phone_number: contactForm.phoneNumber,
      email: contactForm.email,
      content: contactForm.content,
    }).then((res) => console.log(res));
    setValidationCheck(true);
    setEmailValid(true);
  };

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    formType: string
  ) => {
    if (formType === 'companyName')
      setContactForm({ ...contactForm, companyName: e.target.value });
    else if (formType === 'name')
      setContactForm({ ...contactForm, name: e.target.value });
    else if (formType === 'phoneNumber')
      setContactForm({ ...contactForm, phoneNumber: e.target.value });
    else if (formType === 'email')
      setContactForm({ ...contactForm, email: e.target.value });
    else if (formType === 'content')
      setContactForm({ ...contactForm, content: e.target.value });
  };

  useEffect(() => {
    if (!error) {
      console.log(data, error);
      contactForm.content && alert('문의가 성공적으로 접수되었습니다.');
      setContactForm({
        companyName: '',
        name: '',
        phoneNumber: '',
        email: '',
        content: '',
      });
    }
  }, [!loading, error, data]);

  return (
    <ContainerStyle className="block-style">
      <div className="container">
        <div className="contact-form-row">
          <div className="contact-form-column">
            <ArrowUp />
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="회사명을 입력해주세요"
                value={contactForm.companyName}
                onChange={(e) => onChange(e, 'companyName')}
              />
              <div>
                {contactForm.companyName.trim() === '' && !validationCheck
                  ? '회사명을 입력해주세요'
                  : null}
              </div>
            </div>
          </div>
          <div className="contact-form-column">
            <ArrowUp />
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="담당자명을 입력해주세요"
                value={contactForm.name}
                onChange={(e) => onChange(e, 'name')}
              />
              <div>
                {contactForm.name.trim() === '' && !validationCheck
                  ? '담당자명을 입력해주세요요'
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form-row">
          <div className="contact-form-column">
            <ArrowUp />
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="연락처를 입력해주세요"
                value={contactForm.phoneNumber}
                onChange={(e) => onChange(e, 'phoneNumber')}
              />
              <div>
                {contactForm.phoneNumber.trim() === '' && !validationCheck
                  ? '연락처를 입력해주세요'
                  : null}
              </div>
            </div>
          </div>
          <div className="contact-form-column">
            <ArrowUp />
            <div className="input-wrapper">
              <input
                type="email"
                placeholder="이메일 주소를 입력해주세요"
                value={contactForm.email}
                onChange={(e) => onChange(e, 'email')}
              />
              <div>
                {contactForm.email.trim() === '' && !validationCheck
                  ? '이메일 주소를 입력해주세요'
                  : !emailVailid
                    ? '이메일 형식을 확인해주세요'
                    : null}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form-row">
          <div className="contact-form-column">
            <ArrowUp />
            <div className="input-wrapper">
              <textarea
                placeholder="문의하실 내용을 입력해주세요"
                value={contactForm.content}
                onChange={(e) => onChange(e, 'content')}
              />
              <div>
                {contactForm.content.trim() === '' && !validationCheck
                  ? '문의하실 내용을 입력해주세요'
                  : null}
              </div>
            </div>
          </div>
        </div>
        <button className="contact-btn" onClick={onSubmit}>
          문의하기
        </button>
      </div>
    </ContainerStyle>
  );
};

export default ContactFormSection;
