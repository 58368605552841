import React from 'react';
import { useSpring, animated } from 'react-spring';
import { isMobile } from 'react-device-detect';

interface TextAnimationProps {
  text: string;
  isSpecial?: boolean;
  addDelay?: number;
  fontSize: string;
  padding?: string;
  notBold?: boolean;
}

const WeCreateSection: React.FC<TextAnimationProps> = ({
  text,
  isSpecial,
  addDelay,
  fontSize,
  padding,
  notBold,
}) => {
  const letters = text.split('');
  // const fontSize = !isMobile ? '7vw' : '52px';

  return (
    <div
      style={{
        color: !isSpecial ? 'white' : 'black',
        textShadow: isSpecial
          ? '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'
          : 'none',
        display: 'flex',
        padding: padding,
      }}
    >
      {letters.map((letter, index) => (
        <CharacterItem
          key={index}
          fontSize={fontSize}
          index={index * 35 + (addDelay || 0)}
          notBold={notBold}
        >
          {letter}
        </CharacterItem>
      ))}
    </div>
  );
};

interface AnimatedTextLetterProps {
  fontSize: string;
  children: React.ReactNode;
  index: number;
  notBold?: boolean;
}

const CharacterItem: React.FC<AnimatedTextLetterProps> = ({
  fontSize,
  children,
  index,
  notBold,
}) => {
  const props = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 1, transform: 'translateY(110%)' },
    delay: index,
    fontSize: fontSize,
    height: fontSize,
    config: { tension: 1950, friction: 120 },
  });

  const commonStyle = { fontWeight: notBold ? 700 : 900, whiteSpace: 'pre' };

  return (
    <animated.div style={{ ...commonStyle, ...props }}>{children}</animated.div>
  );
};

export default WeCreateSection;
