import React from 'react';
import styled from 'styled-components';

interface PropsType {
  setCurrentItem: (id: number) => void;
  color?: string;
  onClick?: () => void;
}

const ContainerStyle = styled.div`
  cursor: pointer;
`;

const CloseButton: React.FC<PropsType> = ({
  setCurrentItem,
  color,
  onClick,
}) => {
  return (
    <ContainerStyle onClick={() => (onClick ? onClick() : setCurrentItem(0))}>
      <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="1"
          y1="1"
          x2="31"
          y2="31"
          stroke={color ? color : '#000'}
          strokeWidth="2"
        />
        <line
          x1="1"
          y1="31"
          x2="31"
          y2="1"
          stroke={color ? color : '#000'}
          strokeWidth="2"
        />
      </svg>
    </ContainerStyle>
  );
};

export default CloseButton;
