import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import process from 'process';
import {
  useSpring,
  animated,
  useTransition,
  AnimatedProps,
  useSpringRef,
} from 'react-spring';
import Slider from './Slider';

interface ClientImage {
  id: number;
  image: string;
  client_name: string;
  visible?: boolean;
}

const slide = keyframes`
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-50%);
    }
`;

const ContainerStyle = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;

  .client-wrapper {
    width: 100%;
    height: 100%;
    padding: 84px 0 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .client-head {
      width: 100%;
      display: flex;
      gap: 24px;

      .client-title-text {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        padding-left: 14vw;
      }

      .client-head-line {
        display: flex;
        width: 100%;
        align-items: center;

        & > div {
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed white;
          opacity: 0.3;
        }
      }
    }

    .client-body {
      display: grid;
      grid-template-columns: 14vw repeat(auto-fill, minmax(200px, 1fr));
      grid-template-rows: repeat(4, minmax(100px, 1fr));
      grid-auto-rows: 100px;
      grid-auto-flow: column;
      gap: 48px;
      margin-top: 120px;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
`;

const ClientBody: React.FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const [velocity, setVelocity] = useState(0);
  const [isDecelerating, setIsDecelerating] = useState(false);

  const [clientImages, setClientImages] = useState<ClientImage[]>([]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/information/client-image/`
      );
      if (response.data) {
        setClientImages([...response.data, ...response.data]);
      }
    } catch (error) {
      console.error('Fetching error:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setIsDecelerating(false); // 감속 상태 초기화
    setVelocity(0); // 속도 초기화
    setStartX(e.pageX - (ref.current?.offsetLeft ?? 0));
    setScrollLeft(ref.current?.scrollLeft ?? 0);
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging) return;
    const x = e.pageX - (ref.current?.offsetLeft ?? 0);
    const newScrollLeft = scrollLeft - (x - startX) * 2;

    if (ref.current) {
      ref.current.scrollLeft = newScrollLeft;
      setVelocity(newScrollLeft - lastScrollLeft); // 현재와 이전 스크롤 위치 차이를 속도로 설정
      setLastScrollLeft(newScrollLeft);
    }
  };

  const onMouseUpOrLeave = () => {
    setIsDragging(false);
    // 드래그가 끝났지만 속도가 여전히 높은 경우, 감속을 시작합니다.
    if (!isDecelerating && Math.abs(velocity) > 0.95) {
      setIsDecelerating(true);
      requestAnimationFrame(decelerateScroll);
    }
  };

  const decelerateScroll = () => {
    if (!ref.current || !isDecelerating) return;

    // 속도를 조정하여 더 부드러운 감속을 구현합니다.
    const newVelocity = velocity * 0.95;
    setVelocity(newVelocity);

    ref.current.scrollLeft += newVelocity;

    if (Math.abs(newVelocity) < 1) {
      setIsDecelerating(false);
      setVelocity(0); // 감속이 충분히 이루어졌다면 속도를 0으로 설정합니다.
    } else {
      requestAnimationFrame(decelerateScroll);
    }
  };

  return (
    <div
      className="client-body"
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUpOrLeave}
      onMouseLeave={onMouseUpOrLeave}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
    >
      {clientImages.map((clientImage, index) => (
        <ClientItem
          key={index}
          id={clientImage.id}
          image={clientImage.image}
          client_name={clientImage.client_name}
        />
      ))}
      {clientImages.length % 4 !== 0 &&
        clientImages
          .slice(0, clientImages.length % 4)
          .map((clientImage, index) => (
            <ClientItem
              key={index}
              id={clientImage.id}
              image={clientImage.image}
              client_name={clientImage.client_name}
            />
          ))}
    </div>
  );
};

const ClientItem: React.FC<ClientImage> = ({
  id,
  image,
  client_name,
  visible = true,
}) => {
  return (
    <div
      style={{
        marginRight: '20px',
        display: 'inline-block',
        pointerEvents: 'none',
        width: !visible ? '14vw !important' : '180px',
      }}
    >
      <img
        src={image}
        alt={client_name}
        style={{
          pointerEvents: 'none',
          userSelect: 'none',
          display: !visible ? 'none' : 'block',
        }}
      />
    </div>
  );
};

const ClientSection: React.FC = () => {
  return (
    <ContainerStyle className="section-block">
      <div className="client-wrapper">
        <div className="client-head">
          <div className="client-title-text">CLIENT</div>
          <div className="client-head-line">
            <div></div>
          </div>
        </div>
        <Slider speed={600}>
          <ClientBody />
          <ClientBody />
          <ClientBody />
        </Slider>
      </div>
    </ContainerStyle>
  );
};

export default ClientSection;
