import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 40px;

  .aigc-saas-header {
    line-height: 1;
    margin-bottom: 80px;

    .aigc-saas-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .aigc-saas-slash {
        font-size: 74px;
      }

      .aigc-saas-title {
        font-size: 32px;
        padding-top: 8px;
        font-weight: 700;
      }
    }

    .aigc-saas-header-desc {
      font-size: 14px;
      margin-top: 24px;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  .aigc-saas-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 48px;
  }

  .aigc-saas-content-title {
    font-size: 120px;
    font-weight: 700;
    line-height: 1;
  }

  .aigc-saas-numbering {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 40px;
  }

  .aigc-saas-content-wrapper {
    display: flex;
    gap: 40px;

    .aigc-saas-arrow-up {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .aigc-saas-desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 65%;

      .aigc-saas-desc-en {
        font-size: 10px;
        word-break: keep-all;
      }

      .aigc-saas-desc-kr {
        font-size: 14px;
        word-break: keep-all;
      }
    }
  }

  .aigc-saas-content-second {
    padding: 0 0 80px 38px;
  }

  .aigc-saas-content-second > .aigc-saas-content-title {
    font-size: 72px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 24px;
  }
`;

const SalesDrivenMo: React.FC = () => {
  return (
    <ContainerStyle
      className="block-style"
      style={{ paddingTop: '120px', paddingBottom: '120px' }}
    >
      <div className="aigc-saas-header">
        <div className="aigc-saas-wrapper">
          <div className="aigc-saas-slash">/</div>
          <div className="aigc-saas-title">
            <div>SALES</div>
            <div>DRIVEN</div>
            <div className="aigc-saas-header-desc">
              판매 극대화를 위한 솔루션
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Exposure</div>
        <div className="aigc-saas-numbering">노출최적화</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              OPTIMIZE EXPOSURE WITH SHORT FORM, THE MOST EFFECTIVE CONTENT
              THROUGHOUT THE DIGITAL CUSTOMER JOURNEY
            </div>
            <div className="aigc-saas-desc-kr">
              디지털 고객 여정 전반에서, 가장 효과적인 콘텐츠인 숏폼으로 노출
              최적화
            </div>
          </div>
        </div>
        <div
          className="aigc-saas-content-wrapper"
          style={{ paddingTop: '60px' }}
        >
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              MAXIMIZE EXPOSURE BY REALIZING HIGH PERFORMANCE THROUGH
              COST-INNOVATED MULTI-CONTENT MARKETING WITH AI
            </div>
            <div className="aigc-saas-desc-kr">
              AI로 비용 혁신된 멀티콘텐츠 마케팅으로, 높은 퍼포먼스를 실현해
              노출 극대화
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Users</div>
        <div className="aigc-saas-numbering">유저확보</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              SECURING EARLY USERS BY MASS PRODUCING CONTENT WITH HIGH ATTENTION
              AND LIKABILITY USING AI
            </div>
            <div className="aigc-saas-desc-kr">
              주목도와 호감도가 높은 콘텐츠를 AI로 대량 생산해 초기 유저 확보
            </div>
          </div>
        </div>
        <div
          className="aigc-saas-content-wrapper"
          style={{ paddingTop: '60px' }}
        >
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              MULTI-CONTENT THAT PROVIDES NEW STIMULATION UPON REPEATED
              EXPOSURE, MAXIMIZING PURCHASE AND CONVERSION OF USERS
            </div>
            <div className="aigc-saas-desc-kr">
              반복 노출 시 새로운 자극을 주는 멀티콘텐츠로, 구매와 전환 유저
              극대화
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Purchase</div>
        <div className="aigc-saas-numbering">구매전환</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              MAXIMIZE PURCHASE CONVERSION BY USING MORE EFFECTIVE SHORT
              PRODUCTS COMPARED TO IMAGES ON PRODUCT DETAIL PAGES
            </div>
            <div className="aigc-saas-desc-kr">
              제품 상세페이지에 이미지 대비 더 효과적인 숏폼 활용해 구매 전환
              극대화
            </div>
          </div>
        </div>
        <div
          className="aigc-saas-content-wrapper"
          style={{ paddingTop: '60px' }}
        >
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              MAXIMIZE PURCHASE CONVERSION BY IMPROVING CUSTOMER PURCHASING
              EXPERIENCE USING SHORT FORM IN OUR MALL
            </div>
            <div className="aigc-saas-desc-kr">
              자사몰에서 숏폼을 활용한 고객의 구매 경험을 개선해 구매 전환
              극대화
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default SalesDrivenMo;
