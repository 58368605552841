import React, { useState } from 'react';
import { motion } from 'framer-motion';

interface PropsType {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  isBlack?: boolean;
}

const Hamburger: React.FC<PropsType> = ({
  isMenuOpen,
  setIsMenuOpen,
  isBlack,
}) => {
  // const [isOpen, setIsOpen] = useState(false);

  const topLineVariants = {
    closed: { d: 'M 2 8 L 30 8', opacity: 1 },
    opened: { d: 'M 0 0 L 32 32', opacity: 1 },
  };

  const middleLineVariants = {
    closed: { opacity: 1 },
    opened: { opacity: 0 },
  };

  // 수정된 부분: 시작 시 가장 아래 수평선의 길이는 다른 선의 절반, 오른쪽으로 맞춤
  const bottomLineVariants = {
    closed: { d: 'M 16 24 L 30 24', opacity: 1 }, // 시작 시 길이의 절반, 오른쪽으로 맞춤
    opened: { d: 'M 32 0 L 0 32', opacity: 1 }, // 클릭 시 대각선으로 변환
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      style={{ cursor: 'pointer', zIndex: 1000 }}
    >
      <motion.path
        fill="none"
        stroke={isBlack ? '#000' : '#fff'}
        strokeWidth="2"
        variants={topLineVariants}
        animate={isMenuOpen ? 'opened' : 'closed'}
        initial={false}
        transition={{ duration: 0.2 }}
      />
      <motion.path
        fill="none"
        stroke={isBlack ? '#000' : '#fff'}
        strokeWidth="2"
        d="M 2 16 L 30 16"
        variants={middleLineVariants}
        animate={isMenuOpen ? 'opened' : 'closed'}
        initial={false}
        transition={{ opacity: { duration: 0.1 } }}
      />
      <motion.path
        fill="none"
        stroke={isBlack ? '#000' : '#fff'}
        strokeWidth="2"
        variants={bottomLineVariants}
        animate={isMenuOpen ? 'opened' : 'closed'}
        initial={false}
        transition={{ duration: 0.2 }}
      />
    </svg>
  );
};

export default Hamburger;
