import React from 'react';
import {
  Box,
  Button,
  TextField,
  Paper,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../styles/MuiTheme';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/admin');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 480,
            padding: 4,
            boxShadow: 6,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            Directors Tech
          </Typography>
          <TextField label="ID" variant="outlined" margin="normal" fullWidth />
          <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="ID 기억하기" />
          </FormGroup>
          <Button variant="contained" sx={{ height: 56 }} onClick={handleLogin}>
            Login
          </Button>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default AdminLogin;
