import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';
import { ReactComponent as Logo } from '../../assets/images/directors-tech-logo.svg';
import { ReactComponent as Facebook } from '../../assets/images/face_book.svg';
import { ReactComponent as Youtube } from '../../assets/images/youtube.svg';
import { ReactComponent as Instagram } from '../../assets/images/insta.svg';
import axios from 'axios';
import process from 'process';

interface EmailProps {
  email: string;
  assigned_to: string;
}

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 40px;

  .aigc-saas-header {
    line-height: 1;
    margin-bottom: 80px;

    .aigc-saas-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .aigc-saas-slash {
        font-size: 74px;
      }

      .aigc-saas-title {
        font-size: 32px;
        padding-top: 8px;
        font-weight: 700;
      }
    }

    .aigc-saas-header-desc {
      font-size: 14px;
      margin-top: 24px;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  .aigc-saas-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 48px;
  }

  .aigc-saas-content-title {
    font-size: 120px;
    font-weight: 700;
    line-height: 1;
  }

  .aigc-saas-numbering {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 40px;
  }

  .aigc-saas-content-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    line-height: 1;

    .aigc-saas-arrow-up {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .aigc-saas-desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 200px;

      .aigc-saas-desc-en {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
      }

      .aigc-saas-desc-kr {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        line-height: 1.4;
        word-break: keep-all;

        & > a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .aigc-saas-content-second {
    // padding: 0 0 0 38px;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }

  .aigc-saas-content-second > .aigc-saas-content-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 40px;
  }

  .contact-sns-wrap {
    .aigc-saas-desc-en,
    .aigc-saas-desc-kr {
      display: flex;
      align-items: center;
      gap: 5% !important;
      flex-direction: row !important;
      font-size: 24px !important;
    }
  }
`;

const ContactSectionMo: React.FC = () => {
  const [emails, setEmails] = useState<string[]>([]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/information/email/`
      );
      if (response.data) {
        console.log('Client images:', response.data);
        setEmails(response.data.map((item: EmailProps) => item.email));
      }
    } catch (error) {
      console.error('Fetching error:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);
  return (
    <ContainerStyle
      className="block-style"
      style={{ paddingTop: '120px', paddingBottom: '200px' }}
    >
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              <Logo />
            </div>
            <div className="aigc-saas-desc-kr">
              디렉터스테크는 기업과 제품에 대한 딥러닝으로 콘텐츠의 퀄리티와
              마케팅 효율까지 극대화하는 AI솔루션-파트너십을 기다립니다.
            </div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">ADDRESS</div>
            <div className="aigc-saas-desc-kr">
              680-2 Yeoksam-dong, Gangnam-gu, Seoul, Republic of Korea
            </div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">EMAIL</div>
            <div className="aigc-saas-desc-kr">
              {emails.map((email) => (
                <a href={`mailto:${email}`} key={email}>
                  {email}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper contact-sns-wrap">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              <a
                className="aigc-saas-desc-kr"
                href="https://www.facebook.com/directorstech2024"
                target="_blank"
                style={{ color: '#fff', textDecoration: 'none' }}
                rel="noreferrer"
              >
                <Facebook />
                FACEBOOK
              </a>
            </div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper contact-sns-wrap">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              <a
                className="aigc-saas-desc-kr"
                href="https://www.instagram.com/directors_tech"
                target="_blank"
                style={{ color: '#fff', textDecoration: 'none' }}
                rel="noreferrer"
              >
                <Instagram style={{ width: '36px' }} />
                INSTAGRAM
              </a>
            </div>
          </div>
        </div>
        <div className="aigc-saas-content-wrapper contact-sns-wrap">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              <a
                className="aigc-saas-desc-kr"
                href=" https://www.youtube.com/@DirectorstechINC"
                target="_blank"
                style={{ color: '#fff', textDecoration: 'none' }}
                rel="noreferrer"
              >
                <Youtube style={{ width: '36px' }} />
                YOUTUBE
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default ContactSectionMo;
