import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useWorkStore } from '../../store/useStore';

interface PropsType {
  selectedTab: string;
  setSelectedTab: (value: string) => void;
}

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;

  .tab-item {
    height: 28px;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    padding: 6px 8px 8px 8px;
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .selected {
    background-color: #000;
    color: #fff;
    border-radius: 14px;
  }
`;

const tabItems = [
  'ALL',
  '360° PREMIUM',
  'ONE-POINT CREATIVE',
  'CUSTOMIZED CREATIVE',
  'LUXURY',
  'FOOH',
  'AIGC SAAS',
  'AI-DRESSING',
];

function generateRandomString(original: string): string {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456780';

  const randomString = original
    .split('')
    .map((char) => {
      // 영문 알파벳인 경우에만 랜덤하게 바꿉니다.
      if (letters.includes(char)) {
        const randomIndex = Math.floor(Math.random() * letters.length);
        return letters[randomIndex];
      }
      // 영문 알파벳이 아닌 경우(띄어쓰기, 특수문자 등) 원래 문자를 반환합니다.
      return char;
    })
    .join('');

  return randomString;
}

function shuffleStringExceptSpacesAndSpecialChars(original: string): string {
  // 알파벳만 필터링하여 배열로 변환
  const lettersOnly: string[] = original
    .split('')
    .filter((char) => /[a-zA-Z]/.test(char));

  // 알파벳 배열을 무작위로 섞음
  for (let i = lettersOnly.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [lettersOnly[i], lettersOnly[j]] = [lettersOnly[j], lettersOnly[i]];
  }

  // 원본 문자열을 순회하며, 알파벳 위치에 섞인 알파벳을 배치
  let lettersIndex = 0;
  const shuffledString = original
    .split('')
    .map((char) => {
      if (/[a-zA-Z]/.test(char)) {
        return lettersOnly[lettersIndex++];
      }
      return char;
    })
    .join('');

  return shuffledString;
}

const TextHoverEffect: React.FC<{ originText: string; disabled?: boolean }> = ({
  originText,
  disabled,
}) => {
  // 표시할 텍스트 상태
  const [text, setText] = useState(originText);
  // 호버 상태
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let intervalId;

    if (isHovering) {
      const texts = Array.from({ length: 5 }, () =>
        // shuffleStringExceptSpacesAndSpecialChars(originText)
        generateRandomString(originText).toUpperCase()
      ).concat([originText]);

      // const texts = ['텍스트1', '텍스트2', '텍스트3', originText]; // 변경할 텍스트 배열
      let index = 0;

      // 0.5초마다 텍스트 변경
      intervalId = setInterval(() => {
        setText(texts[index]);
        index++;

        // 배열의 마지막에 도달하면 인터벌을 클리어합니다.
        if (index === texts.length) {
          clearInterval(intervalId);
        }
      }, 120);
    } else {
      // 마우스가 떠나면 원래 텍스트로 복원
      setText(originText);
    }

    // 컴포넌트 언마운트 시 인터벌 클리어
    return () => clearInterval(intervalId);
  }, [isHovering]); // 호버 상태 변경 시 useEffect 재실행

  return (
    <div
      style={{
        fontFamily: 'Roboto Mono, monospace',
        cursor: disabled ? 'default' : 'pointer',
      }}
      onMouseEnter={() => !disabled && setIsHovering(true)}
      onMouseLeave={() => !disabled && setIsHovering(false)}
    >
      {text}
    </div>
  );
};

function convertCategoryToCurrent(category: string): string {
  console.log('origin', category);
  switch (category) {
    case '360° PREMIUM':
      return '360-premium';
    case 'ONE-POINT CREATIVE':
      return 'one-point-creative';
    case 'CUSTOMIZED CREATIVE':
      return 'customized-creative';
    case 'AIGC SAAS':
      return 'aigc-saas';
    default:
      return category.toLowerCase();
  }
}

const AIGCNavTab: React.FC<PropsType> = ({ selectedTab, setSelectedTab }) => {
  const { current, setCurrent } = useWorkStore();
  const [selectedIndex, setSelectedIndex] = useState(
    current === 'all'
      ? 0
      : current === '360-premium'
        ? 1
        : current === 'one-point-creative'
          ? 2
          : current === 'customized-creative'
            ? 3
            : current === 'luxury'
              ? 4
              : current === 'fooh'
                ? 5
                : current === 'aigc-saas'
                  ? 6
                  : 7
  );

  const onTabClick = (index: number) => {
    setSelectedIndex(index);
    if (index === 0) {
      setSelectedTab('all');
      // setCurrent('all');
      // setSelectedIndex(0);
    } else if (index === 1) {
      setSelectedTab('360-premium');
      // setCurrent('360° premium');
      // setSelectedIndex(1);
    } else if (index === 2) {
      setSelectedTab('one-point-creative');
      // setCurrent('one-point creative');
      // setSelectedIndex(2);
    } else if (index === 3) {
      setSelectedTab('customized-creative');
      // setCurrent('customized creative');
      // setSelectedIndex(3);
    } else if (index === 4) {
      setSelectedTab('luxury');
      // setCurrent('luxury');
      // setSelectedIndex(4);
    } else if (index === 5) {
      setSelectedTab('fooh');
      // setCurrent('fooh');
      // setSelectedIndex(5);
    } else if (index === 6) {
      setSelectedTab('aigc-saas');
      // setCurrent('aigc-saas');
      // setSelectedIndex(6);
    } else if (index === 7) {
      setSelectedTab('ai-dressing');
      // setCurrent('ai-dressing');
      // setSelectedIndex(7);
    }
  };

  const setTab = (tab: string) => {
    console.log('tab:', tab);
    if (tab === 'all') {
      setSelectedIndex(0);
      return 0;
      // setCurrent('all');
    } else if (tab === '360° premium') {
      setSelectedIndex(1);
      return 1;
      // setCurrent('360-premium');
    } else if (tab === 'one-point creative') {
      setSelectedIndex(2);
      return 2;
      // setCurrent('one-point-creative');
    } else if (tab === 'customized creative') {
      setSelectedIndex(3);
      return 3;
      // setCurrent('customized-creative');
    } else if (tab === 'luxury') {
      setSelectedIndex(4);
      return 4;
      // setCurrent('luxury');
    } else if (tab === 'fooh') {
      setSelectedIndex(5);
      return 5;
      // setCurrent('fooh');
    } else if (tab === 'aigc-saas') {
      setSelectedIndex(6);
      return 6;
      // setCurrent('aigc-saas');
    } else if (tab === 'ai-dressing') {
      setSelectedIndex(7);
      return 7;
      // setCurrent('ai-dressing');
    }
    return -1;
  };

  // useEffect(() => {
  //   setTab(selectedTab);
  // }, [selectedTab]);

  useEffect(() => {
    console.log('current:', current);
    setTab(current);
  }, [current]);

  return (
    <ContainerStyle>
      {tabItems.map((item, index) => (
        <div
          key={index}
          className={`tab-item ${index === selectedIndex ? 'selected' : ''} ${
            item === 'AIGC SAAS' || item === 'AI-DRESSING' ? 'disabled' : ''
          }`}
          onClick={() => {
            console.log('item', item);
            if (!(item === 'AIGC SAAS' || item === 'AI-DRESSING')) {
              onTabClick(index);
              // console.log(item.toLowerCase());
              setCurrent(convertCategoryToCurrent(item));
              setSelectedTab(convertCategoryToCurrent(item));
            }
          }}
        >
          <TextHoverEffect
            originText={item}
            disabled={item === 'AIGC SAAS' || item === 'AI-DRESSING'}
          ></TextHoverEffect>
        </div>
      ))}
    </ContainerStyle>
  );
};

export default AIGCNavTab;
