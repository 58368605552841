import React from 'react';

const SelectItem: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M20.631,10.288v.84a9.128,9.128,0,1,1-5.413-8.343"
          transform="translate(0 0)" // 수정된 값
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M21.241,4l-9.128,9.137L9.375,10.4"
          transform="translate(0 0)" // 수정된 값
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default SelectItem;
