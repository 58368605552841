import React, { ReactNode, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
`;

// 애니메이션 키 프레임 정의는 그대로 유지합니다.

const getSlideAnimation1 = (speed: number) => keyframes`
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-100%);
    }
    50.1% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

const getSlideAnimation2 = (speed: number) => keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-200%);
    }
`;

const OriginalSlidesContainer = styled.div<{ animate: boolean; speed: number }>`
  display: flex;
  animation: ${(props) =>
    props.animate
      ? css`
          ${getSlideAnimation1(props.speed)} ${props.speed}s linear infinite
        `
      : 'none'};
`;

const ClonedSlidesContainer = styled(OriginalSlidesContainer)<{
  speed: number;
}>`
  animation: ${(props) =>
    props.animate
      ? css`
          ${getSlideAnimation2(props.speed)} ${props.speed}s linear infinite
        `
      : 'none'};
`;

type InfiniteSliderProps = {
  children: ReactNode[];
  speed: number; // 속도를 조절할 수 있는 props 추가, 기본값은 20초로 설정할 예정입니다.
  style?: React.CSSProperties;
};

const InfiniteSlider: React.FC<InfiniteSliderProps> = ({
  children,
  style,
  speed,
}) => {
  const [animate, setAnimate] = useState(true);
  const clonedChildren = React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child) : null
  );

  const onMouseEnter = () => setAnimate(false);
  const onMouseLeave = () => setAnimate(true);

  return (
    <Wrapper>
      <OriginalSlidesContainer animate={animate} speed={speed}>
        {children}
      </OriginalSlidesContainer>
      <ClonedSlidesContainer animate={animate} speed={speed}>
        {clonedChildren}
      </ClonedSlidesContainer>
    </Wrapper>
  );
};

export default InfiniteSlider;
