import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import SelectItem from '../portfolio/SelectItem';
import CloseButton from '../navigation/CloseButton';
import axios from 'axios';
import ReactPlayer from 'react-player';
import process from 'process';
import { ReactComponent as Fold } from '../../assets/images/Ic_Fold.svg';
import { ReactComponent as Spread } from '../../assets/images/Ic_Spread.svg';
import { useParams, useNavigate } from 'react-router-dom';

interface PropsType {
  id: number;
  setCurrentItem: (id: number) => void;
}

interface Project {
  id: number;
  contents: {
    id: number;
    content: string;
    thumbnail: string;
    project: number;
  }[];
  thumbnail: string;
  collaboration_brand: string;
  category: string;
  sub_title: string;
  date: string;
  kv_link: string;
  video_direction: 'vertical' | 'horizontal' | 'square';
  is_public: boolean;
  logo: string;
  logo_bg_color: string;
  description: string;
  tech: string;
  keywords: string;
}

// width: ${(props) =>
//       props.videoWidth
//         ? `calc((100vw - ${props.videoWidth}) / 2)`
//         : 'calc(100vw / 3)'};

const slideInFromLeft = keyframes`
    0%, 70% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
`;

const slideInFromLeftSideVertical = keyframes`
    0% {
        transform: translateX(-100%);
    }
    90% {
        transform: translateX(3%);
    }
    100% {
        transform: translateX(0);
    }
`;

const slideInFromRight = keyframes`
    0%, 70% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
`;

const slideInFromLeftVertical = keyframes`
    0% {
        transform: translateX(-300%);
    }
    90% {
        transform: translateX(9%);
    }
    100% {
        transform: translateY(0);
    }
`;

const slideInFromRightVertical = keyframes`
    0% {
        transform: translateX(-200%);
    }
    90% {
        transform: translateX(6%);
    }
    100% {
        transform: translateY(0);
    }
`;

const slideOutToLeft = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
`;

const slideOutToRight = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;

const ContainerStyled = styled.div<{
  logoBgColor: string;
  direction: string;
  isClosing: boolean;
  aspectRatio: string;
  videoWidth: string;
  initialRender: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 100;
  color: #fff;
  font-family: 'Noway', 'Pretendard', sans-serif;

  .bg-close-btn-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 500;
  }

  .open-btn {
    width: 24px;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    right: 136px;
    outline: none;
    border: none;
    //background-color: rgba(255, 255, 255, 0.7);
    //font-size: 24px;
    padding-bottom: 4px;
    z-index: 500;
    cursor: pointer;
  }

  .close-btn {
    width: 24px;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    right: 64px;
    outline: none;
    //background-color: rgba(255, 255, 255, 0.5);
    //font-size: 24px;
    //border: 1px solid #a2a2a2;
    color: #a2a2a2;
    padding-bottom: 4px;
    z-index: 500;
    cursor: pointer;
  }

  .horizontal-player-wrapper {
    position: relative;
    min-width: 100vw;
    height: 100vh; // 화면의 세로 높이에 맞춤
    overflow: hidden;
    z-index: 102;
  }

  .vertical-player-wrapper {
    position: absolute;
    top: 0;
    left: calc(50% - 100vw / 6);
    width: calc(100vw / 3);
    height: 100vh; // 화면의 세로 높이에 맞춤
    overflow: hidden;
    z-index: 102;
    animation: ${(props) =>
        props.direction === 'vertical'
          ? css`
              ${slideInFromRightVertical}
            `
          : 'none'}
      ${(props) => (props.direction === 'vertical' ? '1.3s' : '4s')}
      cubic-bezier(0.56, 0.52, 0.51, 1.02) forwards;

    &:hover {
      cursor: pointer;
    }
  }

  .background-video-area {
    position: fixed;
    top: 0;
    left: 0;
  }

  .loading-text {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
  }

  .left-side {
    width: ${(props) =>
      props.videoWidth && props.direction === 'vertical'
        ? props.videoWidth
        : 'calc(100vw / 3)'};
    width: ${(props) => props.videoWidth};
    height: 100vh;
    background-color: ${(props) =>
      props.logoBgColor ? props.logoBgColor : '#000'};
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 102;

    animation: ${(props) =>
      props.initialRender
        ? ''
        : props.isClosing
          ? css`
              ${slideOutToLeft} 1s forwards
            `
          : css`
              ${slideInFromLeft} 1s forwards
            `};

    & img {
      width: 60%;
      height: auto;
    }
  }

  .right-side {
    width: ${(props) =>
      props.videoWidth && props.direction === 'vertical'
        ? props.videoWidth
        : 'calc(100vw / 3)'};
    width: ${(props) => props.videoWidth};
    height: 100vh;
    padding: 40px;
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #000;
    z-index: 102;

    animation: ${(props) =>
      props.initialRender
        ? ''
        : props.isClosing
          ? css`
              ${slideOutToRight} 1s forwards
            `
          : css`
              ${slideInFromRight} 1s forwards
            `};

    .close-btn-wrapper {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .right-category {
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 0;
    }

    .right-collabo-brand {
      font-size: 48px;
      font-family: 'Noway', 'Pretendard', sans-serif;
      font-weight: 700;
      padding-bottom: 30px;
      margin: 0;
      line-height: 1.2;
      //letter-spacing: -10px;
    }

    .right-subtitle {
      font-family: 'Noway', 'Pretendard', sans-serif;
      font-size: 18px;
      font-weight: 700;
    }

    .right-description {
      font-family: 'Noway', 'Pretendard', sans-serif;
      font-size: 16px;
      padding-bottom: 20px;
      width: 80%;
      white-space: pre-wrap;
    }

    .right-tech {
      font-family: 'Noway', 'Pretendard', sans-serif;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 10px;
    }

    .right-keyword {
      padding-bottom: 20px;
      height: auto;
      display: flex;
      flex-wrap: wrap;

      .keyword-tag {
        font-family: 'Noway', 'Pretendard', sans-serif;
        font-size: 16px;
        height: 32px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 5px 8px 3px 8px;
        //color: #7e7e7e;
        color: #fff;
        background-color: #7e7e7e;
        //border: 1px solid #7e7e7e;
        border-radius: 15px;
        line-height: 1.2;
      }
    }

    .right-video-section {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .thumbnail-wrapper {
        width: 100px;
        height: 100px;
        position: relative;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .hover-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            transition: background-color 0.5s ease-in-out;
          }
        }

        .checkbox-item {
          position: absolute;
          top: 6px;
          left: 6px;
        }
      }
    }

    .right-date {
      position: absolute;
      bottom: 40px;
      left: 40px;
    }
  }
`;

const StyledReactPlayer = styled(ReactPlayer)<{ vertical: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  //  sqare인 경우 비디오를 잘라서 min-width, min-height를 width, height로 변경
  width: ${(props) => (props.vertical ? 'calc(100vw / 3)' : '100vw')};
  height: 100%;
  z-index: ${(props) => (props.vertical ? 101 : 99)};
  pointer-events: none;
`;

const AigcWorkDetail: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [project, setProject] = useState<Project | null>(null);
  const [videoId, setVideoId] = useState<string>('');
  const [originVideoId, setOriginVideoId] = useState<string>('');
  const [selectedThumbnail, setSelectedThumbnail] = useState<number>(-1);
  const [playing, setPlaying] = useState<boolean>(true);
  const [control, setControl] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<string>('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [videoHeight, setVideoHeight] = useState('100vh');
  const [videoWidth, setVideoWidth] = useState('100vw');
  const [aspectRatioVimeo, setAspectRatioVimeo] = useState<number>(16 / 9);
  const { id } = useParams<{ id: string }>();
  const [currentItem, setCurrentItem] = useState(0);
  const navigate = useNavigate();
  const [sideMargin, setSideMargin] = useState(0);
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [videoDirection, setVideoDirection] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);
  const videoUrl = `https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&controls=0&background=1`;

  const accessToken = '2769b98ad6aa7dedf9bee1d71fde9244'; // Vimeo 액세스 토큰

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get<Project>(
        `${process.env.REACT_APP_API_URL}/api/projects/portfolio/${id}/`
      );

      if (response.data) {
        setProject(response.data);
        setVideoDirection(response.data.video_direction);
        setVideoId(
          response.data.kv_link.split('/')[
            response.data.kv_link.split('/').length - 1
          ]
        );
        setOriginVideoId(
          response.data.kv_link.split('/')[
            response.data.kv_link.split('/').length - 1
          ]
        );
      }
    } catch (error) {
      console.error('Fetching error:', error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchVimeoAspectRatio(videoUrl) {
    const apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      const aspectRatio = data.width / data.height;
      return aspectRatio.toFixed(2); // 소수점 두 자리까지 반환
    } catch (error) {
      console.error('Error fetching video data:', error);
      return null;
    }
  }

  useEffect(() => {
    async function calculateVideoWidth() {
      const aspectR = await fetchVimeoAspectRatio(videoUrl);
      aspectR !== null && setAspectRatio(aspectR);
      // if (aspectR) {
      //   const height = window.innerHeight;
      //   const width = height * aspectRatio;
      //   setVideoWidth(width.toFixed(2));
      // }
    }

    calculateVideoWidth();
  }, [videoUrl, windowWidth]);

  useEffect(() => {
    if (videoId) {
      fetchVimeoAspectRatio(`https://vimeo.com/${videoId}`).then(
        (aspectRatio) => {
          setAspectRatioVimeo(Number(aspectRatio));
        }
      );

      axios
        .get(`https://api.vimeo.com/videos/${videoId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          },
        })
        .then((response) => {
          // Vimeo API로부터 width와 height를 받아옴
          const { width, height } = response.data;

          // 종횡비(aspect ratio) 계산
          const aspectRatio = width / height;

          // 뷰포트 너비(100vw)에 대응하는 비디오 높이 계산
          // 이 예에서는 뷰포트 너비를 픽셀 단위로 계산하기 위해 window.innerWidth를 사용
          const viewportWidth = window.innerWidth; // 뷰포트 너비
          const videoHeightFor100vw = viewportWidth / aspectRatio; // 100vw에 대응하는 비디오 높이

          const viewportHeight = window.innerHeight;
          const videoWidthFor100vh = viewportHeight * aspectRatio;

          // 계산된 높이의 절반을 videoHeight 상태에 저장
          setVideoHeight(`${videoHeightFor100vw}px`);
          setVideoWidth(`${videoWidthFor100vh}px`);
          // setVideoUrl(
          //   `https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&controls=0&background=1&muted=1`
          // );
        })
        .catch((error) => {
          console.error('Vimeo API 요청 오류:', error);
        });
    }
  }, [videoId]);

  const handleCloseClick = () => {
    setIsClosing(true);
    // 여기서 필요하다면, 애니메이션이 끝난 후 다른 작업을 수행하기 위한 로직도 추가할 수 있습니다.
  };

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    playing ? setControl(false) : setControl(true);
  }, [playing, control]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    // console.log(window.innerWidth);
  };

  useEffect(() => {
    if (videoRef.current) {
      const height = videoRef.current.getBoundingClientRect().height;
      const width = (height / 16) * 9;
      setVideoHeight(`${height}px`);
    } else {
      console.log('Ref is not set');
    }
  }, [videoUrl, aspectRatioVimeo]);

  // useEffect(() => {
  //   // direction이 horizontal일 경우 초기 렌더링에서 애니메이션을 비활성화
  //   if (project && project.video_direction === 'horizontal') {
  //     setTimeout(() => {
  //       setIsInitialRender(false);
  //       setIsClosing(false);
  //     }, 6000);
  //     // setIsInitialRender(false);
  //   }
  // }, [project]);

  useEffect(() => {
    const calculateMargin = () => {
      const viewportWidth = window.innerWidth;
      const contentWidth = (window.innerHeight / 16) * 9;
      if (window.innerWidth / 3 < contentWidth) {
        setSideMargin(window.innerWidth / 3);
      } else {
        setSideMargin((window.innerWidth - contentWidth) / 2);
      }
    };

    // 컴포넌트 마운트 시 한 번 실행하고, 뷰포트 크기 변경 시 다시 계산
    calculateMargin();
    window.addEventListener('resize', calculateMargin);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', calculateMargin);
    };
  }, []);

  useEffect(() => {
    // if (videoDirection === 'vertical' || videoDirection === 'square') {
    //   // setTimeout(() => setIsClosing(true), 3000);
    // } else if (videoDirection === 'horizontal') {
    console.log('video direction', videoDirection);
    if (videoDirection === 'horizontal' || videoDirection === 'square') {
      setTimeout(() => {
        console.log('set time out');
        setIsClosing(true);
        setIsInitialRender(false);
      }, 2000);
    }
    // }
  }, [videoDirection]);

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <ContainerStyled
      aspectRatio={aspectRatio}
      isClosing={isClosing}
      logoBgColor={project ? project.logo_bg_color : '#000'}
      direction={project ? project.video_direction : 'horizontal'}
      videoWidth={`${sideMargin + 1}px`}
      initialRender={isInitialRender}
    >
      {isClosing && (
        <div className="bg-close-btn-wrapper">
          <CloseButton
            color="#fff"
            setCurrentItem={setCurrentItem}
            onClick={() =>
              window.history.length > 1 ? navigate(-1) : navigate('/aigc-work')
            }
          />
        </div>
      )}
      {isClosing && (
        // <button className="open-btn" onClick={() => setIsClosing(false)}>
        //   {'<'}
        // </button>
        <div
          className="open-btn"
          onClick={() => {
            setIsClosing(false);
            setIsInitialRender(false);
          }}
        >
          <Spread />
        </div>
      )}
      {videoId !== '' ? (
        project?.video_direction === 'horizontal' ? (
          <div
            className="horizontal-player-wrapper"
            onClick={() => togglePlaying()}
            ref={videoRef}
          >
            <StyledReactPlayer
              url={videoUrl}
              playing={playing}
              width="100%"
              height="100%"
              controls={false}
              loop
              vertical={false}
            />
          </div>
        ) : (
          <div
            className="vertical-player-wrapper"
            onClick={() => togglePlaying()}
            ref={videoRef}
          >
            <StyledReactPlayer
              url={videoUrl}
              playing={playing}
              width="100%"
              height="100%"
              controls={false}
              loop
              vertical={false}
            />
          </div>
        )
      ) : (
        <div className="loading-text">Loading...</div>
      )}
      <div className="left-side">
        <img src={project ? project.logo : ''} alt="logo" />
      </div>
      <div className="right-side">
        <div className="close-btn-wrapper">
          <CloseButton
            setCurrentItem={setCurrentItem}
            onClick={() =>
              window.history.length > 1 ? navigate(-1) : navigate('/aigc-work')
            }
          />
        </div>
        <div
          className="close-btn"
          onClick={() => {
            handleCloseClick();
            setIsInitialRender(false);
          }}
        >
          <Fold />
        </div>
        {/*<button className="close-btn" onClick={handleCloseClick}>*/}
        {/*  {'>'}*/}
        {/*</button>*/}
        <div className="right-category">
          {project?.category.toUpperCase() === 'ONE-POINT-CREATIVE'
            ? 'ONE-POINT CREATIVE'
            : project?.category.toUpperCase() === 'CUSTOMIZED-CREATIVE'
              ? 'CUSTOMIZED CREATIVE'
              : project?.category.toUpperCase() === '360-PREMIUM'
                ? '360° PREMIUM'
                : project?.category.toUpperCase()}
        </div>
        <div className="right-collabo-brand">
          {project?.collaboration_brand}
        </div>
        <div className="right-subtitle">{project?.sub_title}</div>
        <div className="right-description">{project?.description}</div>
        <div className="right-tech">{project?.tech}</div>
        <div className="right-keyword">
          {project?.keywords.split(',').map((keyword, index) => (
            <span className="keyword-tag" key={index}>
              {keyword}
            </span>
          ))}
        </div>
        <div className="right-video-section">
          {project?.contents && project?.contents.length > 0 && (
            <div
              className="thumbnail-wrapper"
              key={0}
              onClick={() => {
                setSelectedThumbnail(-1);
                setVideoId(originVideoId);
              }}
            >
              <img src={project?.thumbnail} alt="thumbnail" />
              <div className="hover-box" />
              <div className="checkbox-item">
                {-1 === selectedThumbnail && <SelectItem />}
              </div>
            </div>
          )}
          {project?.contents &&
            project.contents.length > 0 &&
            project.contents.map((content, index) => (
              <div
                className="thumbnail-wrapper"
                key={index}
                onClick={() => {
                  setSelectedThumbnail(index);
                  setVideoId(
                    content.content.split('/')[
                      content.content.split('/').length - 1
                    ]
                  );
                }}
              >
                <img src={content.thumbnail} alt="thumbnail" />
                <div className="hover-box" />
                <div className="checkbox-item">
                  {index === selectedThumbnail && <SelectItem />}
                </div>
              </div>
            ))}
        </div>
        {/*<div className="right-date">{project?.date}</div>*/}
      </div>
    </ContainerStyled>
  );
};
export default AigcWorkDetail;
