import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import Hamburger from './Hamburger';

const blink = keyframes`
    0% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;

interface PropsType {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  isBlack?: boolean;
}

export const HamburgerWrapper = styled.div<{ isBlinking: boolean }>`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 80px;
  top: 20px;
  z-index: 100;
  animation: ${(props) =>
    props.isBlinking
      ? css`
          ${blink} 2s linear infinite
        `
      : 'none'};

  @media (max-width: 768px) {
    right: 28px;
    top: 24px;
    position: fixed;
    width: 28px;
    height: 28px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const HamburgerNavButton: React.FC<PropsType> = ({
  isMenuOpen,
  setIsMenuOpen,
  isBlack,
}) => {
  return (
    <HamburgerWrapper
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      isBlinking={!isMenuOpen}
    >
      <Hamburger
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isBlack={isBlack}
      />
    </HamburgerWrapper>
  );
};

export default HamburgerNavButton;
