import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import useObserverHook from '../../hooks/useObserverHook';
import WeCreateSection from './WeCreateSection';

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const ContainerStyle = styled.div`
  width: 100vw;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  .saas-head {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    position: relative;
    left: 14.3vw;
    top: 4vh;

    .slash-text {
      font-size: 180px;
      font-weight: 500;
      color: #fff;
      margin-right: 20px;
      line-height: 1;
    }

    .saas-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 12px;

      .saas-title-text {
        font-size: 90px;
        font-weight: 700;
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 0.9;
      }
    }

    .saas-head-desc {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      //margin-left: 10vw;
      padding-bottom: 10px;
      font-size: 1.2rem;
      position: absolute;
      bottom: 0;
      left: calc(100vw / 2);
    }
  }

  .sales-driven-body {
    padding-top: 200px;
    padding-left: 14.3vw;
    color: #fff;

    .sales-driven-section {
      &:not(:last-child) {
        margin-bottom: 200px;
      }

      .sale-driven-title {
        font-size: 200px;
        font-weight: 700;
      }

      .sales-driven-content {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding-left: 240px;
      }

      .sales-driven-desc-title {
        font-size: 24px;
        font-weight: 700;
      }

      .sales-driven-desc-body {
        display: flex;
        gap: 60px;

        .sale-driven-desc-en {
          width: 360px;
        }

        & > div {
          display: flex;
        }

        & > :nth-child(1) {
          & svg {
            transform: rotate(45deg);
          }
        }

        &:nth-child(2) {
          & > :nth-child(2) {
          }
        }

        &:nth-child(3) {
        }
      }
    }
  }
`;

const SalesDrivenSection: React.FC = () => {
  const { ref, isVisible } = useObserverHook();

  return (
    <ContainerStyle ref={ref}>
      <div className="saas-head">
        <div className="slash-text">/</div>
        <div className="saas-title-wrapper">
          {isVisible && (
            <>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="SALES"
                    fontSize="90px"
                    addDelay={500}
                    notBold
                  />
                </MaskedContainer>
              </div>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="DRIVEN"
                    fontSize="90px"
                    addDelay={800}
                    notBold
                  />
                </MaskedContainer>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="saas-head-desc">판매 극대화를 위한 솔루션</div>
        </div>
      </div>
      <div className="sales-driven-body">
        <div className="sales-driven-section">
          <div className="sale-driven-title">Exposure</div>
          <div className="sales-driven-content">
            <div className="sales-driven-desc-title">노출최적화</div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                OPTIMIZE EXPOSURE WITH SHORT FORM, THE
                <br />
                MOST EFFECTIVE CONTENT THROUGHOUT THE
                <br />
                DIGITAL CUSTOMER JOURNEY
              </div>
              <div>
                디지털 고객 여정 전반에서, 가장 효과적인 콘텐츠인
                <br />
                숏폼으로 노출 최적화
              </div>
            </div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                MAXIMIZE EXPOSURE BY REALIZING HIGH
                <br />
                PERFORMANCE THROUGH COST-INNOVATED
                <br />
                MULTI-CONTENT MARKETING WITH AI
              </div>
              <div>
                AI로 비용 혁신된 멀티콘텐츠 마케팅으로, 높은 퍼포
                <br />
                먼스를 실현해 노출 극대화
              </div>
            </div>
          </div>
        </div>
        <div className="sales-driven-section">
          <div className="sale-driven-title">Users</div>
          <div className="sales-driven-content">
            <div className="sales-driven-desc-title">유저확보</div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                SECURING EARLY USERS BY MASS PRODUCING
                <br />
                CONTENT WITH HIGH ATTENTION AND
                <br />
                LIKABILITY USING AI
              </div>
              <div>
                주목도와 호감도가 높은 콘텐츠를 AI로 대량 생산해
                <br />
                초기 유저 확보
              </div>
            </div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                MULTI-CONTENT THAT PROVIDES NEW
                <br />
                STIMULATION UPON REPEATED EXPOSURE,
                <br />
                MAXIMIZING PURCHASE AND CONVERSION OF
                <br />
                USERS
              </div>
              <div>
                반복 노출 시 새로운 자극을 주는 멀티콘텐츠로, 구<br />
                매와 전환 유저 극대화
              </div>
            </div>
          </div>
        </div>
        <div className="sales-driven-section">
          <div className="sale-driven-title">Purchase</div>
          <div className="sales-driven-content">
            <div className="sales-driven-desc-title">구매전환</div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                MAXIMIZE PURCHASE CONVERSION BY USING
                <br />
                MORE EFFECTIVE SHORT PRODUCTS COMPARED
                <br />
                TO IMAGES ON PRODUCT DETAIL PAGES
              </div>
              <div>
                제품 상세페이지에 이미지 대비 더 효과적인 숏폼 활<br />
                용해 구매 전환 극대화
              </div>
            </div>
            <div className="sales-driven-desc-body">
              <div>
                <ArrowUp />
              </div>
              <div className="sale-driven-desc-en">
                MAXIMIZE PURCHASE CONVERSION BY
                <br />
                IMPROVING CUSTOMER PURCHASING
                <br />
                EXPERIENCE USING SHORT FORM IN OUR MALL
              </div>
              <div>
                자사몰에서 숏폼을 활용한 고객의 구매 경험을 개선
                <br />해 구매 전환 극대화
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default SalesDrivenSection;
