import React from 'react';
import './App.css';
import LottieAnimation from './components/LottieTest';
import data from '../src/assets/videos/남자걷기_Final_800.json';
import { Routes, Route, Link, BrowserRouter as Router } from 'react-router-dom';
import routes from './route/Routes';
import { RouteItem, renderRoute } from './route/Routes';
import { CssBaseline } from '@mui/material';
import './assets/fonts/fonts.css';

function App() {
  return (
    <div className="App">
      <Router>
        <CssBaseline />
        <Routes>{routes.map(renderRoute)}</Routes>
      </Router>
    </div>
  );
}

export default App;
