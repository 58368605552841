import create, { SetState, GetState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface WorkState {
  current: string;
  setCurrent: (current: string) => void;
}

export const createWorkStore = create(
  devtools(
    persist(
      (set) => ({
        current: 'all',
        setCurrent: (current: string) => {
          set({ current });
          console.log(current);
        },
      }),
      { name: 'work-store' }
    )
  )
) as unknown as () => WorkState;

export const useWorkStore = (): WorkState => {
  const state = createWorkStore();
  return {
    current: state.current,
    setCurrent: state.setCurrent,
  };
};
