import React from 'react';
import { Box } from '@mui/material';

const NotFound = () => {
  return (
    <Box>
      <h1>404 Not Found</h1>
    </Box>
  );
};

export default NotFound;
