import { useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

interface UsePostHookReturn<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  postData: (data: T | FormData, config?: AxiosRequestConfig) => Promise<void>;
}

function usePostHook<T = unknown>(url: string): UsePostHookReturn<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const postData = async (
    patchData: T | FormData,
    config: AxiosRequestConfig = {}
  ) => {
    setLoading(true);
    try {
      // AxiosRequestConfig를 통해 사용자 정의 헤더 등을 설정할 수 있습니다.
      // FormData를 사용할 경우 axios는 자동으로 Content-Type을 설정합니다.
      const response = await axios.post(url, patchData, config);
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error as Error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, postData };
}

export default usePostHook;
