import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import SelectItem from '../portfolio/SelectItem';
import CloseButton from '../navigation/CloseButton';
import axios from 'axios';
import ReactPlayer from 'react-player';
import process from 'process';
import { ReactComponent as Play } from '../../assets/images/Play_icon.svg';
import { ReactComponent as Pause } from '../../assets/images/Ic_Pause.svg';
import { createBrowserHistory, Location, Action } from 'history';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as UpBtn } from '../../assets/images/Ic_Arrow_up_mo.svg';

interface PropsType {
  id: number;
  setCurrentItem: (id: number) => void;
}

interface Project {
  id: number;
  contents: {
    id: number;
    content: string;
    thumbnail: string;
    project: number;
  }[];
  collaboration_brand: string;
  category: string;
  sub_title: string;
  date: string;
  kv_link: string;
  video_direction: 'vertical' | 'horizontal' | 'square';
  is_public: boolean;
  logo: string;
  logo_bg_color: string;
  description: string;
  tech: string;
  keywords: string;
}

const ContainerStyle = styled.div<{ top: string; isDragging: boolean }>`
  width: 100vw;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: hidden;

  .touch-scroll {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    height: 100vh;
    cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
  }

  .play-btn-wrapper {
    position: absolute;
    left: calc(50% - 27px);
    top: calc(50% - 27px);
    border: none;
    outline: none;
    z-index: 100;

    & > div {
      background-color: #000;
      width: 54px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity: 0.5;

      & > svg {
        z-index: 1001;
        stroke: #fff;
      }
    }
  }

  .pause-btn-wrapper {
    position: absolute;
    left: calc(50% - 42px);
    top: ${(props) => `calc(${props.top} - 64px)`};
    z-index: 100;

    & > div {
      border: none;
      outline: none;
      //background-color: #000;
      //opacity: 0.5;
      width: 84px;
      height: 34px;
      border-radius: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      & > svg {
        z-index: 1001;
        //stroke: #fff;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 102;
    padding: 5px 5px 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  .project-content {
    position: relative;
    // top: ${(props) => props.top};
    width: 100%;
    height: 100vh;
    background-color: #fff;
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 60px;
    z-index: 101;
    //transform: translateY(-48px);
    //transition: transform 0.5s ease-in-out;
    -webkit-box-shadow: -1px -7px 14px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -1px -7px 14px 0px rgba(0, 0, 0, 0.2);
    box-shadow: -1px -7px 14px 0px rgba(0, 0, 0, 0.2);

    .play-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      background-color: #0f0f0f;
      width: 40px;
      height: 40px;
    }
  }

  .project-category {
    margin-top: 24px;
    font-weight: 700;
  }

  .project-title {
    font-family: 'Noway', 'Pretendard', sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.1;
    word-break: keep-all;
  }

  .project-subtitle {
    font-size: 1.2rem;
    margin-top: 28px;
    font-weight: 700;
    word-break: keep-all;
  }

  .project-description {
    padding: 12px 0;
    word-break: keep-all;
  }

  .project-tech {
    font-weight: 600;
    margin: 12px 0;
  }

  .project-keywords {
    padding-bottom: 20px;
    height: auto;
    display: flex;
    flex-wrap: wrap;

    .keyword-tag {
      height: 32px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 8px;
      color: #7e7e7e;
      border: 1px solid #7e7e7e;
      border-radius: 15px;
      line-height: 1.2;
    }
  }

  .project-video-section {
    display: flex;
    gap: 10px;

    .thumbnail-wrapper {
      width: 100px;
      height: 150px;
      position: relative;

      & img {
        width: 100%;
        height: 100%;
      }

      .hover-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
          transition: background-color 0.5s ease-in-out;
        }
      }

      .checkbox-item {
        position: absolute;
        top: 6px;
        left: 6px;
      }
    }
  }

  .project-date {
    font-size: 14px;
    margin-top: 12px;
    color: #7e7e7e;
  }

  @media (orientation: landscape) {
    .project-content {
      top: 80vh;
      height: auto;
      padding-bottom: 120px;
    }

    .play-btn-wrapper {
      top: calc(50vh - 27px);
    }
  }
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  width: 100%;
  //height: 100%;
`;

const PlayerWrapper = styled.div<{ aspectRatio: number; orientation: string }>`
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: ${(props) => (1 / props.aspectRatio) * 100}%;
  overflow: hidden;
  pointer-events: none;

  .react-player {
    position: absolute;
    top: ${(props) =>
      props.orientation === 'portrait'
        ? 'calc(50% - 2px)'
        : 'calc(50vh - 2px)'};
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
  }
`;

const customHistory = createBrowserHistory();

const AigcWorkDetailMo: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState<Project | null>(null);
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const [videoId, setVideoId] = useState<string>('');
  const [selectedThumbnail, setSelectedThumbnail] = useState<number>(-1);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoHeight, setVideoHeight] = useState<string>('');
  const [aspectRatioVimeo, setAspectRatioVimeo] = useState<number>(1);
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [currentItem, setCurrentItem] = useState<number>(0);
  const [videoReady, setVideoReady] = useState<boolean>(false);
  const [orientation, setOrientation] = useState('portrait');
  const [panelUpPosition, setPanelUpPosition] = useState<boolean>(false);
  const [videoDirection, setVideoDirection] = useState<string>('horizontal');
  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleTouchStart = (e) => {
    const touchY = e.touches[0].clientY;
    setIsDragging(true);
    setStartY(touchY);
  };

  // const handleTouchMove = (e) => {
  //   if (!isDragging) return;
  //
  //   const targetRect = e.touches[0].target.getBoundingClientRect();
  //   console.log(targetRect.top);
  //   if (targetRect.top < 0) {
  //     setIsDragging(false); // 드래그 상태를 비활성화
  //     return; // 추가 동작을 방지하기 위해 함수 종료
  //   } else {
  //     const touchY = e.touches[0].clientY;
  //
  //     const diff = touchY - startY;
  //     setScrollTop((prevScrollTop) => prevScrollTop - diff);
  //     setStartY(touchY);
  //   }
  // };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const touchY = e.touches[0].clientY;
    const diff = touchY - startY;

    if (scrollRef.current) {
      // 이전 스크롤 위치에서 diff 값을 빼거나 더해줌으로써 스크롤 위치를 업데이트합니다.
      // 여기서는 diff 값을 빼주는 것으로 예시를 들었습니다.
      // 이 부분은 실제 동작에 따라 조정이 필요할 수 있습니다.
      scrollRef.current.scrollTop -= diff;
    }

    setStartY(touchY);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  // const videoUrl = `https://player.vimeo.com/video/${videoId}`;
  const accessToken = '2769b98ad6aa7dedf9bee1d71fde9244'; // Vimeo 액세스 토큰

  const playerStyle = {
    position: 'absolute',
    top: `${videoHeight}`,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    border: 'none',
    padding: '0',
    margin: '0',
  } as React.CSSProperties;

  const videoOnReady = () => {
    setVideoReady(true);
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get<Project>(
        `${process.env.REACT_APP_API_URL}/api/projects/portfolio/${id}/`
      );

      if (response.data) {
        setProject(response.data);
        setVideoId(
          response.data.kv_link.split('/')[
            response.data.kv_link.split('/').length - 1
          ]
        );
        setVideoDirection(response.data.video_direction);
        const vimeoVideoId =
          response.data.kv_link.split('/')[
            response.data.kv_link.split('/').length - 1
          ];
        setVideoUrl(
          `https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1&loop=1&controls=0&background=1&muted=1`
        );
      }
    } catch (error) {
      console.error('Fetching error:', error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchVimeoAspectRatio(videoUrl) {
    const apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      const aspectRatio = data.width / data.height;
      return aspectRatio.toFixed(2); // 소수점 두 자리까지 반환
    } catch (error) {
      console.error('Error fetching video data:', error);
      return null;
    }
  }

  useEffect(() => {
    console.log('ref', scrollRef.current?.getBoundingClientRect().top);
    if (
      scrollRef.current?.getBoundingClientRect().top
        ? scrollRef.current?.getBoundingClientRect().top < 10
        : 10 < 1
    ) {
      if (scrollRef.current) {
        scrollRef.current.scrollTo(0, 0);
      }
      setIsDragging(false);
    }
  }, [startY]);

  useEffect(() => {
    if (videoId) {
      fetchVimeoAspectRatio(`https://vimeo.com/${videoId}`).then(
        (aspectRatio) => {
          console.log('Aspect Ratio:', aspectRatio);
          setAspectRatioVimeo(Number(aspectRatio));
        }
      );

      axios
        .get(`https://api.vimeo.com/videos/${videoId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          },
        })
        .then((response) => {
          // Vimeo API로부터 width와 height를 받아옴
          const { width, height } = response.data;

          // 종횡비(aspect ratio) 계산
          const aspectRatio = width / height;

          // 뷰포트 너비(100vw)에 대응하는 비디오 높이 계산
          // 이 예에서는 뷰포트 너비를 픽셀 단위로 계산하기 위해 window.innerWidth를 사용
          const viewportWidth = window.innerWidth; // 뷰포트 너비
          const videoHeightFor100vw = viewportWidth / aspectRatio; // 100vw에 대응하는 비디오 높이
          console.log('videoHeightFor100vw:', videoHeightFor100vw);

          // 계산된 높이의 절반을 videoHeight 상태에 저장
          setVideoHeight(`${videoHeightFor100vw}px`);
          setVideoUrl(
            `https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&controls=0&background=1&muted=1`
          );
        })
        .catch((error) => {
          console.error('Vimeo API 요청 오류:', error);
        });
    }
  }, [videoId]);

  useEffect(() => {
    fetchItems();

    const handleResize = () => {
      if (window.innerWidth > window.innerHeight) {
        setOrientation('landscape');
      } else {
        setOrientation('portrait');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    const backEventListner = (event: PopStateEvent) => {
      console.log('뒤로 가기 버튼이 눌렸습니다.');

      // 여기에서 사용자에게 경고를 표시하거나, 특정 페이지로 리다이렉션하는 등의 로직을 구현할 수 있습니다.
      alert('뒤로 가기 버튼을 사용할 수 없습니다.');
    };

    window.addEventListener('popstate', backEventListner);

    return () => {
      window.removeEventListener('popstate', backEventListner);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const height = videoRef.current.getBoundingClientRect().height;
      setVideoHeight(`${height}px`);
    } else {
      console.log('Ref is not set');
    }
  }, [videoUrl, aspectRatioVimeo]);

  // useEffect(() => {
  //   console.log(window.matchMedia('(orientation: portrait)').matches);
  // }, []);

  return (
    <ContainerStyle
      top={
        orientation === 'landscape'
          ? `calc(100vh - 300vh)`
          : `calc(${videoHeight} - 100vh - 30px)`
      }
      // top={
      //   !isPlaying && orientation === 'portrait'
      //     ? `calc(${videoHeight} - 24px)`
      //     : isPlaying && orientation === 'landscape'
      //       ? `calc(100vh - 48px)`
      //       : 'auto'
      // }
      isDragging={isDragging}
    >
      {!isPlaying && (
        <div className="close-btn" onClick={() => setCurrentItem(0)}>
          <CloseButton
            setCurrentItem={setCurrentItem}
            color="#fff"
            onClick={() =>
              window.history.length > 1 ? navigate(-1) : navigate('/aigc-work')
            }
          />
        </div>
      )}
      <div className="play-btn-wrapper">
        {!isPlaying && videoReady && (
          <div onClick={() => setIsPlaying(!isPlaying)}>
            <Play style={{ zIndex: 2000 }} />
          </div>
        )}
      </div>
      <PlayerWrapper
        ref={videoRef}
        aspectRatio={aspectRatioVimeo}
        orientation={orientation}
      >
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          width={
            orientation === 'landscape' &&
            project?.video_direction === 'vertical'
              ? 'auto'
              : orientation === 'landscape' &&
                  project?.video_direction === 'square'
                ? '100vh'
                : '100%'
          }
          height={
            orientation === 'landscape' &&
            project?.video_direction === 'vertical'
              ? '100vh'
              : orientation === 'landscape' &&
                  project?.video_direction === 'square'
                ? '100vh'
                : '100%'
          }
          playing={isPlaying}
          controls={false}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          loop
          onReady={videoOnReady}
        />
      </PlayerWrapper>
      {!isPlaying && project && (
        <div
          className="touch-scroll"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {orientation === 'portrait' ? (
            <div
              style={{ height: 'calc(70vh - 48px)', visibility: 'hidden' }}
            ></div>
          ) : orientation === 'landscape' ? (
            <div
              style={{ height: 'calc(5vh - 48px)', visibility: 'hidden' }}
            ></div>
          ) : (
            <div
              style={{ height: 'calc(70vh - 48px)', visibility: 'hidden' }}
            ></div>
          )}
          <div className="project-content">
            <div ref={scrollRef}>
              <div className="project-category">
                {project?.category.toUpperCase() === 'ONE-POINT-CREATIVE'
                  ? 'ONE-POINT CREATIVE'
                  : project?.category.toUpperCase() === 'CUSTOMIZED-CREATIVE'
                    ? 'CUSTOMIZED CREATIVE'
                    : project?.category.toUpperCase() === '360-PREMIUM'
                      ? '360° PREMIUM'
                      : project?.category.toUpperCase()}
              </div>
              <div className="project-title">
                {project?.collaboration_brand}
              </div>
              <div className="project-subtitle">{project?.sub_title}</div>
              <div className="project-description">{project?.description}</div>
              <div className="project-tech">{project?.tech}</div>
              <div className="project-keywords">
                {project?.keywords.split(',').map((keyword, index) => (
                  <span className="keyword-tag" key={index}>
                    {keyword}
                  </span>
                ))}
              </div>
              <div className="project-video-section">
                {project?.contents &&
                  project.contents.length > 1 &&
                  project?.contents.map((content, index) => (
                    <div
                      className="thumbnail-wrapper"
                      key={index}
                      onClick={() => {
                        console.log(
                          'clicked',
                          index,
                          content.content.split('/')
                        );
                        setSelectedThumbnail(index);
                        setVideoId(
                          content.content.split('/')[
                            content.content.split('/').length - 1
                          ]
                        );
                      }}
                    >
                      <img src={content.thumbnail} alt="thumbnail" />
                      <div className="hover-box" />
                      <div className="checkbox-item">
                        {index === selectedThumbnail && <SelectItem />}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </ContainerStyle>
  );
};

export default AigcWorkDetailMo;
