export function extractFilenameFromURL(url: string): string {
  // URL 객체를 생성하여 pathname을 추출
  const pathname = new URL(url).pathname;
  // 마지막 '/' 이후의 문자열을 파일명으로 간주
  const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
  // 파일명의 한글을 디코드
  const decodedFilename = decodeURIComponent(filename);
  return decodedFilename;
}

export function getRandomElement<T>(items: T[]): T {
  const randomIndex = Math.floor(Math.random() * items.length);
  console.log('randomIndex', randomIndex, items[randomIndex]);
  return items[randomIndex];
}
