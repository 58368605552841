import React, { LegacyRef, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/directors-tech-logo.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/IC_Arrow_Up.svg';
import { useNavigate, Link } from 'react-router-dom';
import ArrowIcon from '../navigation/ArrowIcon';
import { isMobile } from 'react-device-detect';
import HamburgerNavButton from '../navigation/HamburgerNavButton';
import MainNavPanel from '../navigation/MainNavPanel';
import { getRandomElement } from '../../util/utils';
import * as process from 'process';
import ReactPlayer from 'react-player';
import { animateScroll } from 'react-scroll';
import { useWorkStore } from '../../store/useStore';

const ContainerStyle = styled.div`
  width: 100vw;
  height: calc(100vh - var(--safe-area-inset-bottom));

  .main-video-section {
    position: relative;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #000;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-logo-section:hover {
    cursor: pointer;
  }

  .dimmed-panel {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 5;

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60%;
      color: white;
      padding-left: 64px;

      & > a {
        font-size: 6rem;
        font-family: 'Antonio', sans-serif;
        font-weight: 700;
        text-decoration: none;
        color: white;
        position: relative;

        .aigc-work-chip {
          position: absolute;
          top: 18px;
          right: 20px;
          width: 84px;
          height: 36px;
          border-radius: 18px;
          background-color: #0027ff;
          font-size: 26px;
          vertical-align: middle;
          text-align: center;
          line-height: 36px;
          padding-top: 2px;

          @media (max-width: 768px) {
            top: 0;
            right: -20px;
            padding-top: 0;
          }
        }
      }

      @media (max-width: 768px) {
        padding-left: 12px;
        justify-content: center;

        & > a {
          font-size: 3.5rem;
        }
      }
    }

    .menu-desc-wrapper {
      height: 60%;
      padding-right: 64px;
      align-self: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .desc-title {
        color: #0027ff;
        font-size: 54px;

        & > div {
          font-family: 'Antonio', sans-serif;
          font-weight: 600;
          line-height: 1;
        }
      }

      .desc-block {
        border-top: 1px solid white;
        padding-top: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .desc-contact {
          color: white;
          display: flex;
          align-items: center;
          gap: 8px;

          &:last-child {
            margin-top: -4px;
          }
        }
      }
    }
  }

  .left-quick-link {
    position: absolute;
    left: 64px;
    bottom: 40px;
    z-index: 2;

    @media (max-width: 768px) {
      left: 12px;
      bottom: 24px;
    }

    & > a {
      text-decoration: none;

      & > div {
        color: white;
        font-family: 'Noto Sans Ko', sans-serif;
        font-weight: 400;
      }
    }
  }

  .right-quick-link {
    position: absolute;
    right: 64px;
    bottom: 40px;
    z-index: 2;

    @media (max-width: 768px) {
      right: 12px;
      bottom: 24px;
    }

    & > a {
      text-decoration: none;

      & > div {
        color: white;
        font-family: 'Noto Sans Ko', sans-serif;
        font-weight: 400;
      }
    }
  }

  .blur {
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(300px);
    z-index: 1;
  }
`;

export const LogoStyle = styled(Logo)`
  position: absolute;
  left: 80px;
  top: 20px;
  z-index: 10;

  @media (max-width: 768px) {
    left: 30px;
    top: 28px;
  }
`;

const LeftTop = styled.div`
  position: absolute;
  left: 22px;
  top: 16px;
  border-top: 1px solid white;
  border-left: 1px solid white;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const LeftBottom = styled.div`
  position: absolute;
  left: 22px;
  bottom: 16px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const RightBottom = styled.div`
  position: absolute;
  right: 22px;
  bottom: 16px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const RightTop = styled.div`
  position: absolute;
  right: 22px;
  top: 16px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const PlusIcon = styled.div<{ isLeft: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: ${(props) => (props.isLeft ? '12px' : 'auto')};
  right: ${(props) => (props.isLeft ? 'auto' : '12px')};
  background-color: transparent;

  & > div {
    width: 20px;
    height: 21px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      height: 1px;
      width: 21px;
      border-top: 1px solid white;
    }

    &::after {
      content: '';
      position: absolute;
      left: 10px;
      top: 0;
      width: 1px;
      height: 21px;
      border-left: 1px solid white;
    }
  }
`;

const StyledReactPlayer = styled(ReactPlayer)<{ vertical: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100%;
  object-fit: cover;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 5;
`;

const mobileVideos = [
  'videos/Directors_M.mp4',
  'videos/Directors_office_M.mp4',
];
const webVideos = ['videos/Directors_0227.mp4', 'videos/Directors_office.mp4'];

const LangindPage: React.FC = () => {
  const [aspectRatio, setAspectRatio] = useState(20 / 9);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [videoId, setVideoId] = useState<string>('');
  const [videoWidth, setVideoWidth] = useState<string>('');
  const [videoHeight, setVideoHeight] = useState<string>('');
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);

  const navigate = useNavigate();

  const { current, setCurrent } = useWorkStore();

  const handleMenuLinkClick = (scrollTarget?: string) => {
    setTimeout(() => {
      if (scrollTarget) {
        const element = document.getElementById(scrollTarget);
        if (scrollTarget === 'about') {
          animateScroll.scrollToTop();
        } else {
          if (element) {
            const offsetTop = element.offsetTop;
            animateScroll.scrollTo(offsetTop);
          }
        }
      }
    }, 600);
  };

  // const videoId = '910709515'; // Vimeo 비디오 ID
  // const videoId = '915461678'; // Vimeo 비디오 ID
  const videoUrl = `https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&controls=0&background=1`;
  const accessToken = '2769b98ad6aa7dedf9bee1d71fde9244'; // Vimeo 액세스 토큰

  // playerStyle 수정 후
  const backVideoContinerStyle = {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  } as React.CSSProperties;

  const backVideoStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100vh',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0',
    margin: '0',
  } as React.CSSProperties;

  const playerContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
  } as React.CSSProperties;

  const playerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: 'auto',
    objectFit: 'cover',
    border: 'none',
    padding: '0',
    margin: '0',
  } as React.CSSProperties;

  // 모바일 환경용 스타일
  const playerStyleMo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: 'auto',
    objectFit: 'cover',
    border: 'none',
    padding: '0',
    margin: '0',
  } as React.CSSProperties;

  useEffect(() => {
    fetchItems();

    axios
      .get(`https://player.vimeo.com/video/${videoId}?`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        const { width, height } = response.data;
        const aspectR = width / height;
        setAspectRatio(aspectR);
        console.log(`비디오 비율: ${aspectR}`);
      })
      .catch((error) => {
        console.error('Vimeo API 요청 오류:', error);
      });
  }, [videoId]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/information/main-video/`
      );

      if (response.data) {
        if (isMobile) {
          const videos = response.data.results.filter(
            (video: {
              id: number;
              is_main: boolean;
              is_public: boolean;
              title: string;
              video_link: string;
              is_mobile: boolean;
            }) => video.is_mobile
          );
          setVideoId(
            getRandomElement<{
              id: number;
              is_main: boolean;
              is_public: boolean;
              title: string;
              video_link: string;
              is_mobile: boolean;
            }>(videos).video_link.split('/')[3]
          );
        } else {
          const videos = response.data.results.filter(
            (video: {
              id: number;
              is_main: boolean;
              is_public: boolean;
              title: string;
              video_link: string;
              is_mobile: boolean;
            }) => !video.is_mobile
          );
          setVideoId(
            getRandomElement<{
              id: number;
              is_main: boolean;
              is_public: boolean;
              title: string;
              video_link: string;
              is_mobile: boolean;
            }>(videos).video_link.split('/')[3]
          );
        }

        // response.data.count > 0 &&
        //   setVideoId(
        //     response.data.results[0].video_link.split('/')[
        //       response.data.results[0].video_link.split('/').length - 1
        //     ]
        //   );
      }

      // if (response.data) {
      //   setProject(response.data);
      //   console.log(response.data.kv_link.split('/')[3], response.data);
      //   setVideoId(
      //     response.data.kv_link.split('/')[
      //     response.data.kv_link.split('/').length - 1
      //       ]
      //   );
      //   const vimeoVideoId =
      //     response.data.kv_link.split('/')[
      //     response.data.kv_link.split('/').length - 1
      //       ];
      //   setVideoUrl(
      //     `https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1&loop=1&controls=0&background=1`
      //   );
      // }
    } catch (error) {
      console.error('Fetching error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('video', videoRef);
    if (videoRef) {
      videoRef.play().catch((error) => console.log('Auto-play failed'));
    }

    // videoRef &&
    //   videoRef.current.play().catch((error) => console.log('Auto-play failed'));
  });

  useEffect(() => {
    setCurrent('all');

    function setScreenSize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setScreenSize();
    window.addEventListener('resize', setScreenSize);

    return () => {
      window.removeEventListener('resize', setScreenSize);
    };
  }, []);

  return (
    <ContainerStyle>
      <div className="main-video-section">
        <div
          className="main-logo-section"
          onClick={() => {
            setIsMenuOpen(false);
            navigate('');
          }}
        >
          <LogoStyle />
        </div>
        {!isMenuOpen && (
          <HamburgerNavButton
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        )}
        {!isMobile && (
          <>
            <LeftTop />
            <LeftBottom />
            <RightTop />
            <RightBottom />
            <PlusIcon isLeft>
              <div />
            </PlusIcon>
            <PlusIcon isLeft={false}>
              <div />
            </PlusIcon>
          </>
        )}
        <div className="left-quick-link">
          <Link to="/aigc-work">
            <ArrowUp />
            <div>Check out</div>
            <div>our Gen AI-CONTENT</div>
          </Link>
        </div>
        <div className="right-quick-link">
          <Link
            to="/about?scroll=contact"
            onClick={() => handleMenuLinkClick('contact')}
          >
            <ArrowUp />
            <div>{"Let's collaborate"}</div>
            <div>with us</div>
          </Link>
        </div>
        <div className="video-container" style={playerContainerStyle}>
          <Link to="/aigc-work">
            <video
              ref={setVideoRef}
              autoPlay
              loop
              muted
              playsInline
              style={isMobile ? playerStyleMo : playerStyle}
              src={
                isMobile
                  ? getRandomElement(mobileVideos)
                  : getRandomElement(webVideos)
              }
            />
          </Link>
        </div>
        {isMenuOpen && (
          <>
            <MainNavPanel isMenuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
          </>
        )}
      </div>
    </ContainerStyle>
  );
};

export default LangindPage;
