import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 40px;

  .aigc-saas-header {
    line-height: 1;
    margin-bottom: 80px;

    .aigc-saas-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .aigc-saas-slash {
        font-size: 74px;
      }

      .aigc-saas-title {
        font-size: 32px;
        padding-top: 8px;
        font-weight: 700;
      }
    }

    .aigc-saas-header-desc {
      font-size: 14px;
      margin-top: 24px;
      line-height: 1.4;
      font-weight: 500;
    }
  }

  .aigc-saas-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 48px;
  }

  .aigc-saas-content-title {
    font-size: 120px;
    font-weight: 700;
    line-height: 1;
  }

  .aigc-saas-numbering {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 40px;
  }

  .aigc-saas-content-wrapper {
    display: flex;
    gap: 40px;

    .aigc-saas-arrow-up {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .aigc-saas-desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 65%;

      .aigc-saas-desc-en {
        font-size: 10px;
        word-break: keep-all;
      }

      .aigc-saas-desc-kr {
        font-size: 14px;
        word-break: keep-all;
      }
    }
  }

  .aigc-saas-content-second {
    padding: 0 0 120px 38px;
  }

  .aigc-saas-content-second > .aigc-saas-content-title {
    font-size: 72px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 40px;
  }
`;

const AiDressingSectionMo: React.FC = () => {
  return (
    <ContainerStyle
      className="block-style"
      style={{ paddingTop: '120px', paddingBottom: '80px' }}
    >
      <div className="aigc-saas-header">
        <div className="aigc-saas-wrapper">
          <div className="aigc-saas-slash">/</div>
          <div className="aigc-saas-title">
            <div>AI</div>
            <div>DRESSING</div>
            <div className="aigc-saas-header-desc">
              AI로 촬영 없이 모델-포즈-배경을
              <br />
              만들어 드립니다
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Sales</div>
        <div className="aigc-saas-numbering">01</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              EVEN IF YOU CHANGE THE IMAGE OF THE CLOTHES TO A MODEL WEARING IT
              RATHER THAN A PHOTO, THE SALES RATE INCREASES BY 40%.
            </div>
            <div className="aigc-saas-desc-kr">
              옷 이미지를 배경제거가 아닌, 모델 착장컷으로만 바꿔도 판매율은 40%
              UP
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Style</div>
        <div className="aigc-saas-numbering">02</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              WITH JUST ONE PRODUCT PHOTO, AI CREATES A PICTORIAL IN THE DESIRED
              STYLE FROM MODEL TO POSE TO BACKGROUND.
            </div>
            <div className="aigc-saas-desc-kr">
              제품 사진 1장만으로, AI가 모델-포즈-배경까지 원하는 스타일로 화보
              생성
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Save</div>
        <div className="aigc-saas-numbering">03</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              INNOVATION IN FILMING COSTS AND TIME, WHICH IS BURDENSOME FOR
              LARGER SKUS AND SMALLER BRANDS.
            </div>
            <div className="aigc-saas-desc-kr">
              SKU가 많을 수록, 작은 브랜드일수록 부담되는 촬영 비용&시간을 혁신
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default AiDressingSectionMo;
