import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';

interface LottieAnimationData {
  v: string;
  fr: number;
  ip: number;
  op: number;
  w: number;
  h: number;

  // 알려지지 않은 추가적인 속성들
  additionalProperties?: Record<string, unknown>;
}

interface LottieAnimationProps {
  height?: number | string;
  width?: number | string;
  margin?: string;
  animationData: LottieAnimationData;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  height,
  width,
  margin,
  animationData,
}) => {
  const [dynamicHeight, setDynamicHeight] = useState<number | string>(
    height ?? 'auto'
  );
  const animationRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    function updateHeight() {
      if (typeof width === 'string' && width.endsWith('vw')) {
        const viewportWidth = window.innerWidth;
        const scale = parseFloat(width) / 100;
        const newHeight =
          viewportWidth * scale * (animationData.h / animationData.w);

        setDynamicHeight(newHeight);
      }
    }

    if (typeof width === 'string' && width.endsWith('vw')) {
      window.addEventListener('resize', updateHeight);
      updateHeight();
    }

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [width, animationData]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
      });
    }, options);

    if (animationRef.current) {
      observer.observe(animationRef.current);
    }

    return () => {
      if (animationRef.current) {
        observer.unobserve(animationRef.current);
      }
    };
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData, // 'videoSource' 대신 'animationData'를 사용
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      ref={animationRef}
      style={{
        width: '100vw',
        padding: margin,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Lottie
        options={defaultOptions}
        height={dynamicHeight}
        width={'100%'}
        isPaused={!isPlaying}
      />
    </div>
  );
};

export default LottieAnimation;
