import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Slash } from '../../assets/images/slash.svg';

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 120px 0 0;

  .aigc-saas-header {
    line-height: 1;
    margin-bottom: 80px;

    .aigc-saas-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .aigc-saas-slash {
        font-size: 74px;
      }

      .aigc-saas-title {
        font-size: 32px;
        padding-top: 8px;
        font-weight: 700;
      }
    }

    .aigc-saas-header-desc {
      font-size: 14px;
      margin-top: 24px;
      line-height: 1.4;
      font-weight: 500;
    }
  }

  .aigc-saas-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 34px;
  }

  .aigc-saas-content-title {
    font-size: 72px;
    font-weight: 700;
    line-height: 1;
  }

  .aigc-saas-content-wrapper {
    display: flex;
    gap: 40px;

    .aigc-saas-arrow-up {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .aigc-saas-desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 200px;

      .aigc-saas-desc-en {
        font-size: 10px;
        word-break: keep-all;
      }

      .aigc-saas-desc-kr {
        font-size: 14px;
        word-break: keep-all;
      }
    }
  }

  .aigc-saas-content-second {
    padding: 96px 0 0 34px;
  }

  .aigc-saas-content-second > .aigc-saas-content-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 40px;
  }
`;

const AigcSaasSection: React.FC = () => {
  return (
    <ContainerStyle className="block-style">
      <div className="aigc-saas-header">
        <div className="aigc-saas-wrapper">
          <div className="aigc-saas-slash">/</div>
          <div className="aigc-saas-title">
            <div>AIGC</div>
            <div>SAAS</div>
            <div className="aigc-saas-header-desc">
              AI로 3D 광고콘텐츠를 더 빠르고
              <br />
              효율적으로 만들어 드립니다.
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content">
        <div className="aigc-saas-content-title">Photo</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              PHOTOS TAKEN WITH A PHONE ARE ALSO OPTIMIZED WITH GENERATIVE AI
              AND USED IMMEDIATELY FOR E-COMMERCE, ETC.
            </div>
            <div className="aigc-saas-desc-kr">
              폰으로 찍은 사진도 생성형AI로 최적화해, 이커머스 등에 바로 사용
            </div>
          </div>
        </div>
      </div>
      <div className="aigc-saas-content-second">
        <div className="aigc-saas-content-title">Easy</div>
        <div className="aigc-saas-content-wrapper">
          <div className="aigc-saas-arrow-up">
            <ArrowUp />
          </div>
          <div className="aigc-saas-desc-wrapper">
            <div className="aigc-saas-desc-en">
              PHOTOS TAKEN WITH A PHONE ARE ALSO OPTIMIZED WITH GENERATIVE AI
              AND USED IMMEDIATELY FOR E-COMMERCE, ETC.
            </div>
            <div className="aigc-saas-desc-kr">
              폰으로 찍은 사진도 생성형AI로 최적화해, 이커머스 등에 바로 사용
            </div>
          </div>
        </div>
      </div>
    </ContainerStyle>
  );
};

export default AigcSaasSection;
