import React from 'react';
import styled from 'styled-components';

const AigcSolution: React.FC = () => {
  return (
    <div>
      <h1>InformationSolution</h1>
    </div>
  );
};

export default AigcSolution;
