import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Logo } from '../../assets/images/directors-tech-logo.svg';
import { ReactComponent as Facebook } from '../../assets/images/face_book.svg';
import { ReactComponent as Youtube } from '../../assets/images/youtube.svg';
import { ReactComponent as Instagram } from '../../assets/images/insta.svg';
import axios from 'axios';
import process from 'process';

interface EmailProps {
  email: string;
  assigned_to: string;
}

const ContainerStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  color: #fff;
  padding-left: 340px;

  .contact-section-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 120px;

    .contact-section {
      width: 480px;
      display: flex;
      gap: 40px;
      align-items: flex-start;

      .arrow-up {
        & > svg {
          transform: rotate(45deg);
        }
      }
    }

    .contact-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 40px;

      .contact-body-title {
        font-size: 38px;
        font-weight: 700;
        line-height: 1;
        display: flex;
        gap: 32px;

        & > svg {
          height: 38px;
          width: auto;
        }
      }

      .contact-body-desc {
        display: flex;
        flex-direction: column;

        & > a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
`;

const ContactSection: React.FC = () => {
  const [emails, setEmails] = useState<string[]>([]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/information/email/`
      );
      if (response.data) {
        console.log('Client images:', response.data);
        setEmails(response.data.map((item: EmailProps) => item.email));
      }
    } catch (error) {
      console.error('Fetching error:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <ContainerStyle className="section-block">
      <div className="contact-section-wrapper">
        <div className="contact-section">
          <div className="arrow-up">
            <ArrowUp />
          </div>
          <div className="contact-body">
            <div className="contact-body-title">
              <Logo />
            </div>
            <div className="contact-body-desc">
              디렉터스테크는 기업과 제품에 대한 딥러닝으로 콘텐츠의 퀄리티와
              마케팅 효율까지 극대화하는 AI솔루션-파트너십을 기다립니다.
            </div>
          </div>
        </div>
        <div className="contact-section">
          <div className="arrow-up">
            <ArrowUp />
          </div>
          <div className="contact-body">
            <div className="contact-body-title">ADDRESS</div>
            <div className="contact-body-desc">
              680-2 Yeoksam-dong, Gangnam-gu, Seoul, Republic of Korea
            </div>
          </div>
        </div>
        <div className="contact-section">
          <div className="arrow-up">
            <ArrowUp />
          </div>
          <div className="contact-body">
            <div className="contact-body-title">EMAIL</div>
            <div className="contact-body-desc">
              {emails.map((email, index) => (
                <a href={`mailto:${email}`} key={index}>
                  {email}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="contact-section">
          <div className="arrow-up">
            <ArrowUp />
          </div>
          <div className="contact-body">
            <a
              className="contact-body-title"
              href="https://www.facebook.com/directorstech2024"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#fff' }}
            >
              <Facebook />
              <div>FACEBOOK</div>
            </a>
          </div>
        </div>
        <div className="contact-section">
          <div className="arrow-up">
            <ArrowUp />
          </div>
          <div className="contact-body">
            <a
              className="contact-body-title"
              href="https://www.instagram.com/directors_tech"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#fff' }}
            >
              <Instagram />
              <div>INSTAGRAM</div>
            </a>
          </div>
        </div>
        <div className="contact-section">
          <div className="arrow-up">
            <ArrowUp />
          </div>
          <div className="contact-body">
            <a
              className="contact-body-title"
              href=" https://www.youtube.com/@DirectorstechINC"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#fff' }}
            >
              <Youtube />
              <div>YOUTUBE</div>
            </a>
          </div>
        </div>
        {/*<div className="contact-section">*/}
        {/*  <div className="arrow-up">*/}
        {/*    <ArrowUp />*/}
        {/*  </div>*/}
        {/*  <div className="contact-body">*/}
        {/*    <div className="contact-body-title" style={{ opacity: 0.5 }}>*/}
        {/*      INSTAGRAM*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </ContainerStyle>
  );
};

export default ContactSection;
